import React, { useState } from "react";

const MultiSelectDropdown = ({
	selectedOptions,
	onChange,
	options,
	dropdownOpen = false,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(dropdownOpen);

	const handleSelect = (o) => {
		console.log(o);
		const isSelected = selectedOptions.some(
			(option) => option.value === o.value
		);
		const updatedSelection = isSelected
			? selectedOptions.filter((option) => option.value !== o.value)
			: [...selectedOptions, o];
		onChange(updatedSelection);
	};

	const isSelected = (o) =>
		selectedOptions.some((option) => option.value === o.value);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<div className='multi-select-container'>
			<div className='multi-select-header' onClick={toggleDropdown}>
				<div className='multi-select-title'>
					{selectedOptions.length > 0
						? selectedOptions
								.map((option) => option.label)
								.join(", ")
						: "Select options"}
				</div>
				<div className='multi-select-arrow'>&#9660;</div>
			</div>
			{isDropdownOpen && (
				<div className='multi-select-dropdown'>
					{options.map((option) => (
						<div key={option.value} className='multi-select-option'>
							<input
								type='checkbox'
								checked={isSelected(option)}
								onChange={() =>
									option.value !== 13
										? handleSelect(option)
										: false
								}
							/>
							<div style={{ width: "100%", paddingLeft: "10px" }}>
								<div className='flex center-left'>
									<div>
										<strong>{option.label}</strong>
									</div>
									<div style={{ marginLeft: "auto" }}>
										<span style={{ color: "#555" }}>
											{" "}
											{option.subscriptionAmount
												? option.subscriptionAmount
												: 0}{" "}
											USD
										</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default MultiSelectDropdown;
