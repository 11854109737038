import { API_URL, SYSTEM_TOKEN } from '../../opl-utilities/constants';
import { ACTIVITY } from '../constants/actions';
import { activityService } from '../services/activities';

export const fetchActivities = options => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .getActivities(options)
            .then(response => {
                dispatch({
                    type: ACTIVITY.FETCH_ACTIVITIES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

/*
export const submitActivity = payload => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .submitActivity(payload)
            .then(response => {
                dispatch({
                    type: ACTIVITY.SUBMIT_ACTIVITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateActivity = payload => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .updateActivity(payload)
            .then(response => {
                dispatch({
                    type: ACTIVITY.UPDATE_ACTIVITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
*/

export const submitActivity = (payload) => (dispatch) => {
    console.log("Payload being sent:", payload)
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/api/1.0.0/activity`, {
            method: 'POST',
            headers: {
                "X-JWT-Assertion": SYSTEM_TOKEN,
                'Content-Type': 'application/json',
            },
            body: (payload),
        })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: ACTIVITY.SUBMIT_ACTIVITY_SUCCESS,
                data: data,
            });
            resolve({ response: data });
        })
        .catch((error) => {
            reject({ error });
        });
    });
};

export const updateActivity = payload => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/api/1.0.0/activity`, {
            method: 'PUT',
            headers: {
                "X-JWT-Assertion": SYSTEM_TOKEN,
                'Content-Type': 'application/json',
            },
            body: (payload),
        })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: ACTIVITY.UPDATE_ACTIVITY_SUCCESS,
                data: data,
            });
            resolve({ response: data });
        })
        .catch((error) => {
            reject({ error });
        });
    });
};


export const deleteActivity = options => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .deleteActivity(options)
            .then(response => {
                dispatch({
                    type: ACTIVITY.DELETE_ACTIVITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};