import { withRouter } from "react-router-dom";

const ResultsPerPage = ({ history, location, label }) => {
	const queryParams = new URLSearchParams(location.search);

	const handleQueryParamChange = (key, value) => {
		queryParams.set("pageNo", 0);
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const getPerPageValue = () => {
		const pageSize =
			new URLSearchParams(location.search).get("pageSize") || "10";
		return pageSize;
	};

	return (
		<div className='flex center-left' style={{ gap: "10px" }}>
			{label && (
				<div>
					<p className='color-primary f-w-500'>{label}</p>
				</div>
			)}
			<div>
				<select
					className='primary'
					style={{ width: "80px" }}
					value={getPerPageValue()}
					onChange={(e) => {
						if (e.target.value) {
							handleQueryParamChange("pageSize", e.target.value);
						}
					}}>
					<option value='12'>12</option>
					<option value='24'>24</option>
					<option value='36'>36</option>
					<option value='48'>48</option>
				</select>
			</div>
		</div>
	);
};

export default withRouter(ResultsPerPage);
