import { useEffect, useState } from "react";
import { API_URL } from "../opl-utilities/constants";
import { withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import VideoURL from "../static/video.mp4";
import LogoImg from "../static/logo-full.png";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";

const LoginAzure = ({ history, location }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");

	const handleLogin = async () => {
		setLoading(true);
		setStatus("");
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/login/client/azure`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						redirectURL: window.location.origin + "/auth/callback",
						state: "web",
					}),
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					console.log("Response", response);
					window.location.href = response.data;
				} else {
					setStatus("No response from server, please try again.");
				}
			} else {
				setStatus("Something went wrong please try again.");
			}
		} catch (e) {
			console.log("Error occurred", e);
		} finally {
			setLoading(false);
		}
	};

	const getClientName = () => {
		let url = window.location.href;
		url = url.split(".");

		let firstPart = url[0].replace("http://", "").replace("https://", "");
		if (
			firstPart.indexOf("/") < 0 &&
			firstPart !== "usp" &&
			firstPart !== "staging" &&
			firstPart !== "dev" &&
			firstPart !== "web"
		) {
			return firstPart;
		} else {
			if (firstPart === "usp") {
				return "Prod Master";
			} else if (firstPart === "staging") {
				return "Stage Master";
			} else if (firstPart === "dev") {
				return "Dev Master";
			} else if (firstPart === "web") {
				return "Web Master";
			} else {
				return firstPart;
			}
		}
	};
    
	useEffect(()=>{
		localStorage.clear();
	},[])

	return (
		<section id='opl_coremax'>
			<section id='login-v2' className='flex center-center'>
				<video
					className='bg-video'
					playsInline={true}
					controls={false}
					autoPlay={true}
					muted={true}
					loop={true}>
					<source src={VideoURL} type='video/mp4' />
				</video>
				<div>
					<div className='form'>
						<img className='logo' src={LogoImg} alt='coremax' />
						<h5
							className='color-primary f-w-700 t-a-c'
							style={{
								marginBottom: "10px",
							}}>
							Login to your account.
						</h5>
						<p className='color-light'>
							You will be redirected to microsoft online portal.
							Enter the email associated with CoreMax to confirm
							your login.
						</p>
						<hr />
						<div>
							<ButtonTextIcon
								onClick={() => handleLogin()}
								title='Click to login'
								style={{
									width: "100%",
									height: "52px",
								}}
								disabled={loading}
								icon={
									<>
										{loading ? (
											<LoaderIcon />
										) : (
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='19.995'
												height='19.996'
												viewBox='0 0 19.995 19.996'>
												<path
													d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
													transform='translate(-2.014 -1.995)'
												/>
											</svg>
										)}
									</>
								}
							/>
						</div>
						{status && (
							<>
								<p
									className='color-secondary l-h-1-2'
									style={{ paddingTop: "15px" }}>
									{status}
								</p>
							</>
						)}
					</div>
					<p
						className='t-a-c color-white'
						style={{
							position: "relative",
							zIndex: 3,
							paddingTop: "12px",
							fontSize: "11px",
						}}>
						<span style={{ opacity: 0.4 }}>Client: </span>
						<span>{getClientName()}</span>
					</p>
				</div>
			</section>
		</section>
	);
};

export default withRouter(LoginAzure);
