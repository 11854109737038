import { NavLink, withRouter } from "react-router-dom";
import { formatServerDate } from "../../opl-utilities/helpers";
import { useEffect, useState } from "react";
import CustomModal from "../../components/common/CustomModal";
import ConfirmationModal from "../../components/common/ui/ConfirmationModal";
import PopUpModal from "../../components/common/PopUpModal";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { API_URL, SYSTEM_TOKEN } from "../../opl-utilities/constants";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useUI } from "../../context/ui";

function convertToYYYYMMDD(dateString) {
	const [month, day, year] = dateString.split("/");
	return `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
}

const getRespectiveBorderColor = (id) => {
	switch (id) {
		case 1:
			return "#F08D3B";
		case 2:
			return "#E65464";
		case 3:
			return "#56BAEC";
		case 4:
			return "#8e44ad";
		case 5:
			return "#27ae60";
		default:
			return "#f0f0f0";
	}
};

const StatusIcon = ({ id }) => {
	switch (id) {
		case "incomplete":
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						viewBox='0 0 32 32'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M16,32A16,16,0,0,1,4.686,4.686,16,16,0,1,1,27.314,27.314,15.9,15.9,0,0,1,16,32ZM13,10a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V13a3,3,0,0,0-3-3Z'
							fill={getRespectiveBorderColor(1)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(1),
						}}>
						Ongoing
					</p>
				</div>
			);
		case "complete":
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11,17,6,11.7l1.4-1.43,3.574,3.736L17.545,7,19,8.4Z'
							fill={getRespectiveBorderColor(5)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(5),
						}}>
						Completed
					</p>
				</div>
			);
		default:
			return <></>;
	}
};

const getRandomColor = () => {
	const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
	const randomIndex = Math.floor(Math.random() * array.length);
	return array[randomIndex];
};

const getPatientName = (obj) => {
	if (obj) {
		if (obj.firstName && obj.lastName) {
			return `${obj.firstName} ${obj.lastName}`;
		} else {
			return "Not Selected";
		}
	} else {
		return "Not Selected";
	}
};

const getInitials = (obj) => {
	if (obj) {
		if (obj.firstName && obj.lastName) {
			const nameInitial = obj.firstName[0].toUpperCase();
			const lastNameInitial = obj.lastName[0].toUpperCase();
			return nameInitial + lastNameInitial;
		} else {
			return "NA";
		}
	} else {
		return "NA";
	}
};

const getRandomWidth = () => {
	const w = Math.floor(Math.random() * 101);
	return w + "%";
};

const ActivityCard = ({ history, location, data = null }) => {
	const { addToastMessage } = useUI();
	const queryParams = new URLSearchParams(location.search);
	const [isOpen, setIsOpen] = useState(false);
	const [viewActivity, setViewActivity] = useState("");

	const status = queryParams.get("status") || "incomplete";
	const statusId = queryParams.get("status") === "incomplete" ? 1 : 5;

	const InfoItem = ({ icon, label, name, ...rest }) => {
		return (
			<div {...rest}>
				<div className='flex center-left' style={{ gap: "10px" }}>
					<div>{icon}</div>
					<div style={{ overflow: "hidden" }}>
						<p
							className='f-w-300'
							style={{
								color: "#aaaaaa",
								fontSize: "10px",
								lineHeight: 1,
								paddingBottom: "2px",
							}}>
							{label}
						</p>
						<p
							style={{
								width: "132px",
								lineHeight: 1,
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}>
							{name}
						</p>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='activity-card pos-rel'>
			<div
				style={{
					position: "absolute",
					content: "",
					height: "4px",
					left: "-1px",
					right: "-1px",
					bottom: "-2px",
					background: getRespectiveBorderColor(statusId),
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px",
				}}></div>
			<div
				className='col-1-1 flex center-left'
				style={{ gap: "10px", paddingBottom: "5px" }}>
				<h5 className='f-w-700'>
					<span className='f-w-300'>#</span>&nbsp;
					{data.patientActivityDAO.id}
				</h5>
				<hr
					style={{
						outline: "none",
						border: "none",
						height: "16px",
						width: "2px",
						background: "#EBEBEB",
					}}
				/>
				<StatusIcon id={status} />
				<div style={{ marginLeft: "auto" }}>
					<div
						className='menu-container'
						style={{ position: "relative" }}>
						<svg
							onClick={() => setIsOpen(!isOpen)}
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							style={{ cursor: "pointer" }}>
							<path
								fill={isOpen ? "#283f54" : "#8f8f8f"}
								d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM7.5,14A1.5,1.5,0,1,1,9,12.5,1.5,1.5,0,0,1,7.5,14ZM12,14a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,14Zm4.5,0A1.5,1.5,0,1,1,18,12.5,1.5,1.5,0,0,1,16.5,14Z'
								transform='translate(24) rotate(90)'
							/>
						</svg>
						{isOpen && (
							<ul>
								<li>
									<NavLink
										to={{
											pathname: "/edit-activity",
											state: {
												id: data.patientActivityDAO.id,
											},
										}}>
										<div
											style={{
												width: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-start",
												gap: "4px",
												padding: "0 8px",
											}}>
											<svg
												width='12'
												height='12'
												style={{
													height: "12px",
													width: "12px",
												}}
												viewBox='0 0 24 24'>
												<path
													fillRule='evenodd'
													clipRule='evenodd'
													d='M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z'
													fill='#283f54'
												/>
											</svg>
											<p
												style={{
													fontSize: "11px",
													lineHeight: "28px",
												}}>
												Edit
											</p>
										</div>
									</NavLink>
								</li>
								<CustomModal
									selector={
										<li>
											<div
												style={{
													width: "100%",
													display: "flex",
													alignItems: "center",
													justifyContent:
														"flex-start",
													gap: "4px",
													padding: "0 8px",
												}}>
												<svg
													height='12'
													width='12'
													viewBox='0 0 330 330'
													style={{
														height: "12px",
														width: "12px",
													}}>
													<g>
														<g>
															<path
																fill='#283f54'
																d='M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z'
															/>
														</g>
														<g>
															<path
																fill='#283f54'
																d='M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z'
															/>
														</g>
														<g>
															<path
																fill='#283f54'
																d='M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z'
															/>
														</g>
													</g>
												</svg>
												<p
													style={{
														fontSize: "11px",
														lineHeight: "28px",
													}}>
													Delete
												</p>
											</div>
										</li>
									}
									content={
										<ConfirmationModal
											onConfirm={async () => {
												try {
													const resp = await fetch(
														API_URL +
															`/api/1.0.0/activity/${data.patientActivityDAO.id}`,
														{
															method: "PUT",
															headers: {
																"Content-Type":
																	"application/json",
																"X-JWT-Assertion":
																	SYSTEM_TOKEN,
															},
														}
													);
													if (resp.ok) {
														const responseData =
															await resp.json();
														if (
															responseData.result
																.responseCode ===
															200
														) {
															addToastMessage({
																status: true,
																message: `Activity deleted successfully.`,
															});
															queryParams.set(
																"r",
																Math.floor(
																	10000 +
																		Math.random() *
																			90000
																)
															);
															history.push({
																search: queryParams.toString(),
															});
														} else {
															addToastMessage({
																status: false,
																message: `Something went wrong while deleting activity. Please try again.`,
															});
														}
													} else {
														addToastMessage({
															status: false,
															message: `Something went wrong while deleting activity. Please try again.`,
														});
													}
												} catch (e) {
													console.log(e);
													addToastMessage({
														status: false,
														message: `Something went wrong while deleting activity. Please try again.`,
													});
												}
											}}
											message='Are you sure you want to delete this activity?'
										/>
									}
								/>
							</ul>
						)}
					</div>
				</div>
			</div>
			<div
				style={{
					padding: "12px 0 12px 0 ",
				}}>
				<div
					className='info-row flex center-center'
					style={{
						gap: "10px",
						paddingBottom: "10px",
					}}>
					<InfoItem
						className='col-1-1 info-row-column'
						icon={
							<svg
								color={getRandomColor()}
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								style={{
									height: "20px",
									width: "20px",
								}}>
								<path d='M18 20a6 6 0 0 0-12 0'></path>
								<circle cx='12' cy='10' r='4'></circle>
								<circle cx='12' cy='12' r='10'></circle>
							</svg>
						}
						label={"Assistant"}
						name={
							data?.patientActivityDAO?.event_surgical_assitant
								? `${data?.patientActivityDAO?.event_surgical_assitant?.name} ${data?.patientActivityDAO?.event_surgical_assitant?.lastName}`
								: "NA"
						}
					/>
					<InfoItem
						className='col-1-1 info-row-column'
						icon={
							<svg
								color={getRandomColor()}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								style={{
									height: "20px",
									width: "20px",
								}}>
								<path d='M12 6v4'></path>
								<path d='M14 14h-4'></path>
								<path d='M14 18h-4'></path>
								<path d='M14 8h-4'></path>
								<path d='M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2'></path>
								<path d='M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18'></path>
							</svg>
						}
						label={"Facility"}
						name={
							data?.patientActivityDAO?.event_facility
								?.facilityName || "NA"
						}
					/>
				</div>
				<div
					className='info-row flex center-center'
					style={{
						gap: "10px",
						paddingBottom: "10px",
					}}>
					<InfoItem
						className='col-1-1 info-row-column'
						icon={
							<svg
								color={getRandomColor()}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								style={{
									height: "20px",
									width: "20px",
								}}>
								<path d='M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3'></path>
								<path d='M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4'></path>
								<circle cx='20' cy='10' r='2'></circle>
							</svg>
						}
						label={"Surgeon"}
						name={
							data?.eventSurgeon
								? `${data?.eventSurgeon?.firstName} ${data?.eventSurgeon?.lastName}`
								: "NA"
						}
					/>
					<InfoItem
						className='col-1-1 info-row-column'
						icon={
							<svg
								color={getRandomColor()}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								style={{
									height: "20px",
									width: "20px",
								}}>
								<path d='M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z'></path>
								<path d='M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5'></path>
								<path d='M4 15v-3a6 6 0 0 1 6-6h0'></path>
								<path d='M14 6h0a6 6 0 0 1 6 6v3'></path>
							</svg>
						}
						label={"Event Type"}
						name={data?.eventType?.eventTypeName || "NA"}
					/>
				</div>
				<div
					className='info-row flex center-center'
					style={{
						gap: "10px",
						paddingBottom: "10px",
					}}>
					<InfoItem
						className='col-1-1 info-row-column'
						icon={
							<svg
								color={getRandomColor()}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								style={{
									height: "20px",
									width: "20px",
								}}>
								<path d='M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5'></path>
								<path d='M16 2v4'></path>
								<path d='M8 2v4'></path>
								<path d='M3 10h5'></path>
								<path d='M17.5 17.5L16 16.3V14'></path>
								<circle cx='16' cy='16' r='6'></circle>
							</svg>
						}
						label={"Created By"}
						name={
							data?.createdBy
								? `${data?.createdBy?.name} ${data?.createdBy?.lastName}`
								: "NA"
						}
					/>
					<InfoItem
						className='col-1-1 info-row-column'
						icon={
							<svg
								color={getRandomColor()}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								style={{
									height: "20px",
									width: "20px",
								}}>
								<path d='M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5'></path>
								<path d='M16 2v4'></path>
								<path d='M8 2v4'></path>
								<path d='M3 10h5'></path>
								<path d='M17.5 17.5L16 16.3V14'></path>
								<circle cx='16' cy='16' r='6'></circle>
							</svg>
						}
						label={"Modified By"}
						name={
							data?.modifiedBy
								? `${data?.modifiedBy?.name} ${data?.modifiedBy?.lastName}`
								: "NA"
						}
					/>
				</div>
			</div>
			<div style={{ paddingBottom: "10px" }}>
				<div>
					<div className='col-1-1 flex center-left'>
						<p
							className='color-faded f-w-300 l-h-1'
							style={{ fontSize: "9px" }}>
							{data?.patientActivityDAO?.event_start_date
								? formatServerDate(
										data?.patientActivityDAO
											?.event_start_date
								  )
								: "NA"}{" "}
							{data?.patientActivityDAO?.event_start_time || ""}
						</p>
						<p
							className='color-faded f-w-300 l-h-1 mar-l-a'
							style={{ fontSize: "9px" }}>
							{data?.patientActivityDAO?.event_end_date
								? formatServerDate(
										data?.patientActivityDAO?.event_end_date
								  )
								: "NA"}{" "}
							{data?.patientActivityDAO?.event_end_time || ""}
						</p>
					</div>
				</div>
				<div
					className='flex center-left'
					style={{
						padding: "5px 0",
					}}>
					<div
						className='b-r-100'
						style={{
							height: "12px",
							width: "12px",
							background: "white",
							border: "solid 3px",
							borderColor: "#283f54",
						}}></div>
					<div
						className='pos-rel'
						style={{
							flex: 1,
							height: "2px",
							background: "#f0f0f0",
						}}>
						<div
							style={{
								position: "absolute",
								content: "''",
								height: "2px",
								left: "0",
								top: 0,
								background: getRespectiveBorderColor(statusId),
								width: getRandomWidth(),
							}}></div>
					</div>
					<div
						className='b-r-100'
						style={{
							height: "12px",
							width: "12px",
							background: "white",
							border: "solid 3px",
							borderColor: getRespectiveBorderColor(statusId),
						}}></div>
				</div>
			</div>
			<div className='col-1-1 flex center-left' style={{ gap: "10px" }}>
				<div
					className='b-r-100 flex center-center'
					style={{
						height: "28px",
						width: "28px",
						background: getRandomColor(),
						border: "solid 1px rgba(0,0,0,0.08)",
						fontSize: "12px",
						fontWeight: "300",
						color: "white",
					}}>
					{getInitials(data?.patientActivityDAO?.patient)}
				</div>
				<div>
					<p
						className='f-w-300'
						style={{
							color: "#aaaaaa",
							fontSize: "11px",
							lineHeight: 1.2,
						}}>
						Patient
					</p>
					<p
						className='f-w-600 color-primary'
						style={{ lineHeight: 1 }}>
						{getPatientName(data?.patientActivityDAO?.patient)}
					</p>
				</div>
				<div
					style={{ gap: "6px", marginLeft: "auto" }}
					className='flex center-left cursor-pointer'
					onClick={() => setViewActivity(data.patientActivityDAO.id)}>
					<p
						className='f-w-300'
						style={{
							color: "#283f54",
							fontSize: "11px",
							lineHeight: 1,
						}}>
						View Details
					</p>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='19.996'
						height='19.995'
						viewBox='0 0 19.996 19.995'
						className='mar-l-a'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M12.007,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10ZM13.53,8.21s1.5,1.505,3.255,3.259a.75.75,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.74.74,0,0,1-.526.217.751.751,0,0,1-.534-1.278l1.977-1.977H7.755a.75.75,0,0,1,0-1.5h6.693L12.47,9.271a.745.745,0,0,1,.006-1.054.754.754,0,0,1,.53-.222.738.738,0,0,1,.524.215Z'
							transform='translate(-2.009 -2)'
							fill='#8f8f8f'
						/>
					</svg>
				</div>
			</div>
			{viewActivity && (
				<PopUpModal>
					<ActivityDetailsContent
						setViewActivity={setViewActivity}
						id={data?.patientActivityDAO?.id}
						status={status}
						statusId={statusId}
					/>
				</PopUpModal>
			)}
		</div>
	);
};

const ActivityDetailsContent = ({ setViewActivity, id, status, statusId }) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const InfoItem = ({ icon, label, name, ...rest }) => {
		return (
			<div {...rest}>
				<div className='flex center-left' style={{ gap: "10px" }}>
					<div>{icon}</div>
					<div style={{ overflow: "hidden" }}>
						<p
							className='f-w-300'
							style={{
								color: "#aaaaaa",
								fontSize: "10px",
								lineHeight: 1,
								paddingBottom: "2px",
							}}>
							{label}
						</p>
						<p
							style={{
								width: "200px",
								lineHeight: 1,
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}>
							{name}
						</p>
					</div>
				</div>
			</div>
		);
	};

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/activity/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": SYSTEM_TOKEN,
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setData(response.data);
					setLoading(false);
				}
			}
		} catch (e) {
			setError("Failed to fetch activity data.");
			setLoading(false);
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) callApi(id);
	}, [id]);

	return (
		<section id='opl_coremax'>
			<section id='custom-modal'>
				<div className='inner'>
					<div className='box' id='activity-detail-box'>
						<div className='flex center-left'>
							<div>
								<h6 className='color-primary f-w-300'>
									Activity Details
								</h6>
							</div>
							<div style={{ marginLeft: "auto" }}>
								<ButtonLightTextIcon
									title='Return Back'
									onClick={() => setViewActivity("")}
									icon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.017,2a10,10,0,1,1-10,10,10,10,0,0,1,10-10Zm0,1.5a8.5,8.5,0,1,0,8.5,8.5,8.5,8.5,0,0,0-8.5-8.5ZM10.489,8.21s-1.5,1.505-3.255,3.259a.75.75,0,0,0,0,1.061c1.753,1.754,3.254,3.258,3.254,3.258a.74.74,0,0,0,.526.217.751.751,0,0,0,.534-1.278L9.571,12.75h6.693a.75.75,0,0,0,0-1.5H9.571l1.978-1.979a.745.745,0,0,0-.006-1.054.754.754,0,0,0-.53-.222.738.738,0,0,0-.524.215Z'
												transform='translate(-2.019 -1.995)'
											/>
										</svg>
									}
								/>
							</div>
						</div>
						<div
							style={{
								height: "60vh",
								margin: "10px",
								overflowY: "scroll",
								padding: "20px 5px",
								overflowX: "hidden",
								scrollbarWidth: "thin",
							}}>
							{loading && (
								<div
									className='col-1-1'
									style={{
										height: "40px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<LoaderIcon
										style={{
											width: "20px",
											height: "20px",
										}}
									/>
								</div>
							)}
							{!loading && error && (
								<p
									className='f-w-300 color-primary'
									style={{ color: "#c0392b" }}>
									{error}
								</p>
							)}
							{data && !loading && !error && (
								<div className='activity-card pos-rel'>
									<div
										style={{
											position: "absolute",
											content: "",
											height: "4px",
											left: "-1px",
											right: "-1px",
											bottom: "-2px",
											borderBottomLeftRadius: "4px",
											borderBottomRightRadius: "4px",
										}}></div>
									<div
										className='col-1-1 flex center-left'
										style={{
											gap: "10px",
											paddingBottom: "5px",
										}}>
										<h5 className='f-w-700'>
											<span className='f-w-300'>#</span>
											&nbsp;
											{data.patientActivityDAO.id}
										</h5>
										<hr
											style={{
												outline: "none",
												border: "none",
												height: "16px",
												width: "2px",
												background: "#EBEBEB",
											}}
										/>
										<StatusIcon id={status} />
									</div>
									<div
										style={{
											padding: "12px 0 12px 0 ",
										}}>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M18 20a6 6 0 0 0-12 0'></path>
														<circle
															cx='12'
															cy='10'
															r='4'></circle>
														<circle
															cx='12'
															cy='12'
															r='10'></circle>
													</svg>
												}
												label={"Patient"}
												name={getPatientName(
													data?.patientActivityDAO
														?.patient
												)}
											/>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}
														className='lucide lucide-info h-4 w-4 text-muted-foreground'
														data-id='30'>
														<circle
															cx='12'
															cy='12'
															r='10'></circle>
														<path d='M12 16v-4'></path>
														<path d='M12 8h.01'></path>
													</svg>
												}
												label={"Account Numbe"}
												name={
													data?.patientActivityDAO
														?.account_number || "NA"
												}
											/>
										</div>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														data-id='28'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
														<path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
													</svg>
												}
												label={"Pay To"}
												name={
													data?.payToAdmin
														?.payToName || "NA"
												}
											/>
										</div>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3'></path>
														<path d='M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4'></path>
														<circle
															cx='20'
															cy='10'
															r='2'></circle>
													</svg>
												}
												label={"Surgeon"}
												name={
													data?.eventSurgeon
														? `${data?.eventSurgeon?.firstName} ${data?.eventSurgeon?.lastName}`
														: "NA"
												}
											/>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M12 6v4'></path>
														<path d='M14 14h-4'></path>
														<path d='M14 18h-4'></path>
														<path d='M14 8h-4'></path>
														<path d='M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2'></path>
														<path d='M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18'></path>
													</svg>
												}
												label={"Facility"}
												name={
													data?.patientActivityDAO
														?.event_facility
														?.facilityName || "NA"
												}
											/>
										</div>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M18 20a6 6 0 0 0-12 0'></path>
														<circle
															cx='12'
															cy='10'
															r='4'></circle>
														<circle
															cx='12'
															cy='12'
															r='10'></circle>
													</svg>
												}
												label={"Assistant"}
												name={
													data?.patientActivityDAO
														?.event_surgical_assitant
														? `${data?.patientActivityDAO?.event_surgical_assitant?.name} ${data?.patientActivityDAO?.event_surgical_assitant?.lastName}`
														: "NA"
												}
											/>

											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z'></path>
														<path d='M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5'></path>
														<path d='M4 15v-3a6 6 0 0 1 6-6h0'></path>
														<path d='M14 6h0a6 6 0 0 1 6 6v3'></path>
													</svg>
												}
												label={"Event Type"}
												name={
													data?.eventType
														?.eventTypeName || "NA"
												}
											/>
										</div>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
														data-id='72'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
														<circle
															cx='12'
															cy='10'
															r='3'></circle>
													</svg>
												}
												label={"Place of Service"}
												name={
													data?.placeOfService?.name
														? `${data?.placeOfService?.name}`
														: "NA"
												}
											/>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														className='lucide lucide-flag h-4 w-4 text-muted-foreground'
														data-id='76'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'></path>
														<line
															x1='4'
															x2='4'
															y1='22'
															y2='15'></line>
													</svg>
												}
												label={"Event Flags"}
												name={
													data?.eventFlags
														?.scheduleFlag
														? `${data?.eventFlags?.scheduleFlag}`
														: "NA"
												}
											/>
										</div>

										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<div className='col-1-1 info-row-column'>
												<div
													className='flex center-left'
													style={{
														gap: "10px",
														alignItems:
															"flex-start",
													}}>
													<div>
														<svg
															color={getRandomColor()}
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='lucide lucide-info h-4 w-4 text-muted-foreground'
															data-id='30'
															style={{
																height: "20px",
																width: "20px",
															}}>
															<circle
																cx='12'
																cy='12'
																r='10'></circle>
															<path d='M12 16v-4'></path>
															<path d='M12 8h.01'></path>
														</svg>
													</div>
													<div
														style={{
															overflow: "hidden",
														}}>
														<p
															className='f-w-300'
															style={{
																color: "#aaaaaa",
																fontSize:
																	"10px",
																lineHeight: 1,
																paddingBottom:
																	"2px",
															}}>
															Procedures
														</p>
														<p
															style={{
																whiteSpace:
																	"normal",
																overflowWrap:
																	"break-word",
																overflow:
																	"hidden",
																textOverflow:
																	"ellipsis",
																width: "100%",
															}}>
															{data?.procedure
																?.length
																? data.procedure
																		.map(
																			(
																				procedure
																			) =>
																				procedure.procedureName
																		)
																		.join(
																			", "
																		)
																: "NA"}
														</p>
													</div>
												</div>
											</div>
										</div>
										<hr
											style={{
												marginTop: "10px",
												paddingBottom: "10px",
											}}
										/>
										<div style={{ marginBottom: "16px" }}>
											<p
												style={{
													fontWeight: "700",
													paddingBottom: "8px",
												}}>
												Files
											</p>
											<div
												style={{
													display: "grid",
													gap: "16px",
												}}>
												{data.patientActivityDAO?.files
													.length ? (
													data.patientActivityDAO?.files.map(
														(file, index) => (
															<div
																key={index}
																style={{
																	display:
																		"flex",
																	alignItems:
																		"center",
																	justifyContent:
																		"space-between",
																	padding:
																		"5px 12px",
																	backgroundColor:
																		"rgb(245, 245, 245)",
																	borderRadius:
																		"8px",
																}}>
																<div
																	style={{
																		display:
																			"flex",
																		alignItems:
																			"center",
																		gap: "12px",
																	}}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																		fill='none'
																		stroke='currentColor'
																		strokeWidth='2'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																		style={{
																			height: "20px",
																			width: "20px",
																		}}>
																		<path d='M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z'></path>
																		<path d='M14 2v4a2 2 0 0 0 2 2h4'></path>
																		<path d='M10 9H8'></path>
																		<path d='M16 13H8'></path>
																		<path d='M16 17H8'></path>
																	</svg>
																	<div>
																		<div
																			style={{
																				fontSize:
																					"12px",
																				fontWeight:
																					"400",
																			}}>
																			{file.fileUrl
																				.split(
																					"/"
																				)
																				.pop()}
																		</div>
																		<div
																			style={{
																				fontSize:
																					"10px",
																				color: "#7D7D7D",
																				textTransform:
																					"capitalize",
																			}}>
																			{
																				file.type
																			}
																		</div>
																	</div>
																</div>
																<div
																	style={{
																		display:
																			"flex",
																		gap: "8px",
																	}}>
																	<a
																		href={
																			file.fileS3Url
																		}
																		target='_blank'
																		style={{
																			background:
																				"transparent",
																			border: "none",
																			cursor: "pointer",
																		}}
																		title='View file'>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																			fill='none'
																			stroke='currentColor'
																			strokeWidth='2'
																			strokeLinecap='round'
																			strokeLinejoin='round'
																			style={{
																				height: "16px",
																				width: "16px",
																				color: "#000",
																			}}>
																			<path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
																			<circle
																				cx='12'
																				cy='12'
																				r='3'></circle>
																		</svg>
																	</a>
																</div>
															</div>
														)
													)
												) : (
													<div
														style={{
															display: "flex",
															alignItems:
																"center",
															justifyContent:
																"center",
															padding: "5px 12px",
															backgroundColor:
																"rgb(245, 245, 245)",
															borderRadius: "8px",
														}}>
														<div
															style={{
																display: "flex",
																alignItems:
																	"center",
																gap: "12px",
															}}>
															<div>
																<div
																	style={{
																		fontSize:
																			"12px",
																		fontWeight:
																			"400",
																	}}>
																	No file
																	found.
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>

										<hr
											style={{
												marginTop: "10px",
												paddingBottom: "12px",
											}}
										/>

										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														data-id='71'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M8 2v4'></path>
														<path d='M16 2v4'></path>
														<rect
															width='18'
															height='18'
															x='3'
															y='4'
															rx='2'></rect>
														<path d='M3 10h18'></path>
													</svg>
												}
												label={"Admission Date:"}
												name={
													data?.patientActivityDAO
														?.admission_date
														? formatServerDate(
																// convertToYYYYMMDD(
																data
																	?.patientActivityDAO
																	?.admission_date
																// )
														  )
														: "NA"
												}
											/>

											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5'></path>
														<path d='M16 2v4'></path>
														<path d='M8 2v4'></path>
														<path d='M3 10h5'></path>
														<path d='M17.5 17.5L16 16.3V14'></path>
														<circle
															cx='16'
															cy='16'
															r='6'></circle>
													</svg>
												}
												label={"Start Date & Time"}
												name={
													(data?.patientActivityDAO
														?.event_start_date
														? formatServerDate(
																data
																	?.patientActivityDAO
																	?.event_start_date
														  )
														: "NA") +
													" " +
													(data?.patientActivityDAO
														?.event_start_time ||
														"")
												}
											/>
										</div>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
													<svg
														color={getRandomColor()}
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
														style={{
															height: "20px",
															width: "20px",
														}}>
														<path d='M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5'></path>
														<path d='M16 2v4'></path>
														<path d='M8 2v4'></path>
														<path d='M3 10h5'></path>
														<path d='M17.5 17.5L16 16.3V14'></path>
														<circle
															cx='16'
															cy='16'
															r='6'></circle>
													</svg>
												}
												label={"End Date & Time"}
												name={
													(data?.patientActivityDAO
														?.event_end_date
														? formatServerDate(
																data
																	?.patientActivityDAO
																	?.event_end_date
														  )
														: "NA") +
													" " +
													(data?.patientActivityDAO
														?.event_end_time || "")
												}
											/>
										</div>

										<hr
											style={{
												marginTop: "10px",
												paddingBottom: "10px",
											}}
										/>

										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "20px",
											}}>
											<div className='col-1-1 info-row-column'>
												<div
													className='flex center-left'
													style={{ gap: "10px" }}>
													<div>
														<svg
															color={getRandomColor()}
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='lucide lucide-info h-4 w-4 text-muted-foreground'
															data-id='30'
															style={{
																height: "20px",
																width: "20px",
															}}>
															<circle
																cx='12'
																cy='12'
																r='10'></circle>
															<path d='M12 16v-4'></path>
															<path d='M12 8h.01'></path>
														</svg>
													</div>
													<div
														style={{
															overflow: "hidden",
														}}>
														<p
															className='f-w-300'
															style={{
																color: "#aaaaaa",
																fontSize:
																	"10px",
																lineHeight: 1,
																paddingBottom:
																	"2px",
															}}>
															Note
														</p>
														<p
															style={{
																whiteSpace:
																	"normal",
																overflowWrap:
																	"break-word",
																overflow:
																	"hidden",
																textOverflow:
																	"ellipsis",
																width: "100%",
															}}>
															{data
																?.patientActivityDAO
																?.event_notes ||
																"NA"}
														</p>
													</div>
												</div>
											</div>
										</div>

										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												label={"Created By"}
												name={
													data?.createdBy
														? `${data?.createdBy?.name} ${data?.createdBy?.lastName}`
														: "NA"
												}
											/>
											<InfoItem
												className='col-1-1 info-row-column'
												label={"Modified By"}
												name={
													data?.modifiedBy
														? `${data?.modifiedBy?.name} ${data?.modifiedBy?.lastName}`
														: "NA"
												}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
						<div>
							<NavLink
								to={
									data?.patientActivityDAO?.id
										? {
												pathname: "/edit-activity",
												state: {
													id: data?.patientActivityDAO
														?.id,
												},
										  }
										: "#"
								}>
								<ButtonLightTextIcon
									title='Edit Activity'
									icon={
										<svg
											width='15'
											height='15'
											style={{
												height: "15px",
												width: "15px",
											}}
											viewBox='0 0 24 24'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z'
												fill='#283f54'
											/>
										</svg>
									}
								/>
							</NavLink>
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default withRouter(ActivityCard);