import React from "react";
import ReactDOM from "react-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { Provider } from "react-redux";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "jquery/dist/jquery.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import configureStore from "./javascript/configureStore";
import App from "./javascript/components/environments/App";

// import { Providers } from "@microsoft/mgt-element";
// import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { LOCAL_STORAGE_KEYS } from "./javascript/constants/common";

const LightTheme = React.lazy(() => import("./lightTheme"));
const DarkTheme = React.lazy(() => import("./darkTheme"));

library.add(faBars);

const ThemeSelector = ({ children }) => {
	const loggedInUser = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
	);
	const theme = loggedInUser ? loggedInUser.theme : "dark";
	return (
		<>
			<React.Suspense fallback={<></>}>
				{theme === "light" && <LightTheme />}
				{theme === "dark" && <DarkTheme />}
			</React.Suspense>
			{children}
		</>
	);
};

// Live Setting

// Providers.globalProvider = new Msal2Provider({
//     clientId: 'dcfa1fb7-ea4c-4e0d-9ef1-a92ff9ce7569',
//     authority: 'https://login.microsoftonline.com/9427176d-8fc3-4a4f-b926-7effae45d114',
//     redirectUri: 'https://usp.coremaxcloud.com/login'
// });

// // Staging Setting

// Providers.globalProvider = new Msal2Provider({
//     clientId: 'dcfa1fb7-ea4c-4e0d-9ef1-a92ff9ce7569',
//     authority: 'https://login.microsoftonline.com/9427176d-8fc3-4a4f-b926-7effae45d114',
//     redirectUri: 'https://staging.universalscloud.com/login'
// });

// Developmenet Setting

// Providers.globalProvider = new Msal2Provider({
//     clientId: 'dcfa1fb7-ea4c-4e0d-9ef1-a92ff9ce7569',
//     authority: 'https://login.microsoftonline.com/9427176d-8fc3-4a4f-b926-7effae45d114',
//     redirectUri: 'https://universalscloud.com/login'
// });

// Local Setting
// Providers.globalProvider = new Msal2Provider({
//     clientId: 'dcfa1fb7-ea4c-4e0d-9ef1-a92ff9ce7569',
//     authority: 'https://login.microsoftonline.com/9427176d-8fc3-4a4f-b926-7effae45d114',
//     redirectUri: 'http://localhost:3000/login'
// });

// Providers.globalProvider = new Msal2Provider({
// 	clientId: "dcfa1fb7-ea4c-4e0d-9ef1-a92ff9ce7569",
// 	authority:
// 		"https://login.microsoftonline.com/9427176d-8fc3-4a4f-b926-7effae45d114",
// 	redirectUri: process.env.AZURE_REDIRECT_URL,
// });

ReactDOM.render(
	<Provider store={configureStore}>
		<ThemeSelector>
			<App />
			<NotificationContainer />
		</ThemeSelector>
	</Provider>,
	document.getElementById("root")
);
