import { useEffect, useState } from "react";

const CircleRemoveIcon = ({rest}) => (
	<svg
	  stroke="currentColor"
	  fill="currentColor"
	  strokeWidth="0"
	  viewBox="0 0 24 24"
	  height="1em"
	  width="1em"
	  {...rest}
	>
	  <g id="Circle_Remove">
		<g>
		  <path d="M9.525,13.765a.5.5,0,0,0,.71.71c.59-.59,1.175-1.18,1.765-1.76l1.765,1.76a.5.5,0,0,0,.71-.71c-.59-.58-1.18-1.175-1.76-1.765.41-.42.82-.825,1.23-1.235.18-.18.35-.36.53-.53a.5.5,0,0,0-.71-.71L12,11.293,10.235,9.525a.5.5,0,0,0-.71.71L11.293,12Z"></path>
		  <path d="M12,21.933A9.933,9.933,0,1,1,21.934,12,9.945,9.945,0,0,1,12,21.933ZM12,3.067A8.933,8.933,0,1,0,20.934,12,8.944,8.944,0,0,0,12,3.067Z"></path>
		</g>
	  </g>
	</svg>
  );

  const CircleCheckIcon = ({rest}) => (
	<svg
	  stroke="currentColor"
	  fill="currentColor"
	  strokeWidth="0"
	  viewBox="0 0 24 24"
	  height="1em"
	  width="1em"
	  {...rest}
	>
	  <g id="Circle_Check">
		<g>
		  <path d="M15.81,10.4a.5.5,0,0,0-.71-.71l-3.56,3.56L9.81,11.52a.5.5,0,0,0-.71.71l2.08,2.08a.513.513,0,0,0,.71,0Z"></path>
		  <path d="M12,21.934A9.934,9.934,0,1,1,21.933,12,9.945,9.945,0,0,1,12,21.934ZM12,3.067A8.934,8.934,0,1,0,20.933,12,8.944,8.944,0,0,0,12,3.067Z"></path>
		</g>
	  </g>
	</svg>
  );
  
const SingleToast = ({ data, removeToast }) => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (data) {
			setVisible(true);

			const timer = setTimeout(() => {
				setVisible(false);
				removeToast(data.id);
			}, 4000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [data]);

	if (visible) {
		return (
			<div
				className='single-toast'
				onClick={() => {
					removeToast(data.id);
				}}>
				<div
					className={`icon ${
						data.status ? "icon-success" : "icon-failure"
					}`}>
					{data.status ? (
						<CircleCheckIcon color='#27ae60' />
					) : (
						<CircleRemoveIcon color='#e74c3c' />
					)}
				</div>
				<p className='color-white'>{data.message}</p>
				<hr />
			</div>
		);
	} else {
		return null;
	}
};

export default SingleToast;
