import { withRouter } from "react-router-dom";
import { formatServerDate } from "../../opl-utilities/helpers";

function formatDateToCustomFormats(dateStr) {
	const date = new Date(dateStr);
	const fullDateTimeOptions = {
		day: "2-digit",
		month: "short",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	};
	const fullDateTime = date.toLocaleString("en-GB", fullDateTimeOptions);
	const monthName = date
		.toLocaleString("en-GB", { month: "short" })
		.toUpperCase();
	const dayOfMonth = date.toLocaleString("en-GB", { day: "2-digit" });
	return {
		fullDateTime,
		monthName,
		dayOfMonth,
	};
}

const getRespectiveBorderColor = (id) => {
	switch (id) {
		case 1:
			return "#F08D3B";
		case 2:
			return "#E65464";
		case 3:
			return "#56BAEC";
		case 4:
			return "#8e44ad";
		case 5:
			return "#27ae60";
		default:
			return "#f0f0f0";
	}
};

const StatusIcon = ({ id }) => {
	switch (id) {
		case 1:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						viewBox='0 0 32 32'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M16,32A16,16,0,0,1,4.686,4.686,16,16,0,1,1,27.314,27.314,15.9,15.9,0,0,1,16,32ZM13,10a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V13a3,3,0,0,0-3-3Z'
							fill={getRespectiveBorderColor(1)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(1),
						}}>
						Not Started
					</p>
				</div>
			);
		case 2:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M14,22a2,2,0,1,1-2-2A2,2,0,0,1,14,22ZM12,0a2,2,0,1,0,2,2A2,2,0,0,0,12,0ZM22,10a2,2,0,1,1-2,2A2,2,0,0,1,22,10ZM0,12a2,2,0,1,0,2-2A2,2,0,0,0,0,12ZM19,3a2,2,0,1,1-2,2A2,2,0,0,1,19,3Zm0,14a2,2,0,1,1-2,2A2,2,0,0,1,19,17ZM5,3A2,2,0,1,1,3,5,2,2,0,0,1,5,3ZM5,17a2,2,0,1,1-2,2A2,2,0,0,1,5,17Z'
							fill={getRespectiveBorderColor(2)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(2),
						}}>
						In Progress
					</p>
				</div>
			);
		case 3:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm2,16V13.375S8.381,13.38,5,17c1.588-6.916,9-8.292,9-8.292V6l5,5.021Z'
							fill={getRespectiveBorderColor(3)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(3),
						}}>
						Forwarded
					</p>
				</div>
			);
		case 4:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						viewBox='0 0 32 32'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M16,32A16,16,0,0,1,4.686,4.686,16,16,0,1,1,27.314,27.314,15.9,15.9,0,0,1,16,32ZM18,8V24h6V8ZM8,8V24h6V8Z'
							fill={getRespectiveBorderColor(4)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(4),
						}}>
						Paused
					</p>
				</div>
			);
		case 5:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11,17,6,11.7l1.4-1.43,3.574,3.736L17.545,7,19,8.4Z'
							fill={getRespectiveBorderColor(5)}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: getRespectiveBorderColor(5),
						}}>
						Completed
					</p>
				</div>
			);
		default:
			return <></>;
	}
};

const getRandomColor = () => {
	const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
	const randomIndex = Math.floor(Math.random() * array.length);
	return array[randomIndex];
};

const getInitials = (obj) => {
	if (obj) {
		if (obj.firstName && obj.lastName) {
			const nameInitial = obj.firstName[0].toUpperCase();
			const lastNameInitial = obj.lastName[0].toUpperCase();
			return nameInitial + lastNameInitial;
		} else {
			return "NA";
		}
	} else {
		return "NA";
	}
};

const getPatientName = (obj) => {
	if (obj) {
		if (obj.firstName && obj.lastName) {
			return `${obj.firstName} ${obj.lastName}`;
		} else {
			return "Not Selected";
		}
	} else {
		return "Not Selected";
	}
};

const getRandomWidth = () => {
	const w = Math.floor(Math.random() * 101);
	return w + "%";
};

const SingleTask = ({ history, location, id, data = null }) => {
	const queryParams = new URLSearchParams(location.search);

	const updateQueryParam = (key, value) => {
		queryParams.set(key, value);
		history.push({ search: queryParams.toString() });
	}; 

	return (
		<div
			className='one pos-rel'
			onClick={() => updateQueryParam("id", data?.id)}
			style={{
				borderColor:
					parseInt(id) === data?.id
						? getRespectiveBorderColor(data.statusId)
						: "#ebf0f4",
				background:
					parseInt(id) === parseInt(data?.id) ? "#f4feff" : "white",
			}}>
			<div
				style={{
					position: "absolute",
					content: "",
					height: "5px",
					left: "-1px",
					right: "-1px",
					bottom: "-2px",
					background: getRespectiveBorderColor(data.statusId),
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px",
				}}></div>
			<div className='col-1-1 flex center-left' style={{ gap: "10px" }}>
				<h5 className='f-w-700'>
					<span className='f-w-300'>#</span>&nbsp;
					{data.patientActivity.id}
				</h5>
				<hr
					style={{
						outline: "none",
						border: "none",
						height: "16px",
						width: "2px",
						background: "#EBEBEB",
					}}
				/>
				<StatusIcon id={data.statusId} />
			</div>
			<div className='flex center-left' style={{ padding: "10px 0" }}>
				<div
					className='b-r-100'
					style={{
						height: "12px",
						width: "12px",
						border: "solid 3px",
						borderColor: getRandomColor(),
					}}></div>
				<hr
					style={{
						outline: "none",
						border: "none",
						width: "12px",
						height: "2px",
						backgroundColor: "#cfcfcf",
					}}
				/>
				<p
					className='l-h-1 t-t-u'
					style={{
						backgroundColor: "#f0f0f0",
						color: "#8f8f8f",
						padding: "0 6px",
						fontSize: "9px",
						lineHeight: "20px",
						whiteSpace: "nowrap",
						borderRadius: "3px",
					}}>
					{data?.stepsConfig?.stepName || "Default Step"}
				</p>
			</div>
			<div
				style={{
					padding: "0px 0 32px 0 ",
				}}>
				<p
					className='color-primary f-w-300 of-hid l-h-1-2'
					style={{
						width: "100%",
						display: "-webkit-box",
						WebkitLineClamp: 2,
						WebkitBoxOrient: "vertical",
						overflow: "hidden",
						textOverflow: "ellipsis",
						maxHeight: "34px",
					}}>
					Event #{data.patientActivity.event_facility?.id} at{" "}
					{data.patientActivity.event_facility?.facilityName} for
					insurance {data.patientActivity.insurance?.description}.
				</p>
			</div>
			<div>
				<div className='col-1-1 flex center-left'>
					<p
						className='color-faded f-w-300 l-h-1'
						style={{ fontSize: "9px" }}> 
						{data?.patientActivity?.event_start_date
							? formatServerDate(
								data?.patientActivity
									?.event_start_date
							)
							: "NA"}{" "}
						{data?.patientActivity?.event_start_time || ""}
					</p>
					<p
						className='color-faded f-w-300 l-h-1 mar-l-a'
						style={{ fontSize: "9px" }}> 
						{data?.patientActivity?.event_end_date
							? formatServerDate(
								data?.patientActivity?.event_end_date
							)
							: "NA"}{" "}
						{data?.patientActivity?.event_end_time || ""}
					</p>
				</div>
			</div>
			<div
				className='flex center-left'
				style={{
					padding: "5px 0",
				}}>
				<div
					className='b-r-100'
					style={{
						height: "12px",
						width: "12px",
						background: "white",
						border: "solid 3px",
						borderColor: "#283f54",
					}}></div>
				<div
					className='pos-rel'
					style={{
						flex: 1,
						height: "2px",
						background: "#f0f0f0",
					}}>
					<div
						style={{
							position: "absolute",
							content: "''",
							height: "2px",
							left: "0",
							top: 0,
							background: getRespectiveBorderColor(data.statusId),
							width: getRandomWidth(),
						}}></div>
				</div>
				<div
					className='b-r-100'
					style={{
						height: "12px",
						width: "12px",
						background: "white",
						border: "solid 3px",
						borderColor: getRespectiveBorderColor(data.statusId),
					}}></div>
			</div>
			<div className='t-a-c' style={{ marginBottom: "32px" }}>
				<p
					className='f-w-300 l-h-1'
					style={{ color: "#aaaaaa", fontSize: "11px" }}>
					Assistant
				</p>
				<p className='color-primary l-h-1-2 f-w-600'>{`${
					data.patientActivity.event_surgical_assitant?.name || "_"
				} ${
					data.patientActivity.event_surgical_assitant?.lastName || ""
				}`}</p>
			</div>
			<div className='col-1-1 flex center-center' style={{ gap: "10px" }}>
				<div
					className='b-r-100 flex center-center'
					style={{
						height: "28px",
						width: "28px",
						background: getRandomColor(),
						border: "solid 1px rgba(0,0,0,0.08)",
						fontSize: "12px",
						fontWeight: "300",
						color: "white",
					}}>
					{getInitials(data.patientActivity.patient)}
				</div>
				<div>
					<p
						className='f-w-300 l-h-1-2'
						style={{ color: "#aaaaaa", fontSize: "11px" }}>
						Patient
					</p>
					<p className='f-w-600 l-h-1 color-primary' style={{}}>
						{getPatientName(data.patientActivity.patient)}
					</p>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='19.996'
					height='19.995'
					viewBox='0 0 19.996 19.995'
					className='mar-l-a'
					style={{ height: "24px", width: "auto" }}>
					<path
						d='M12.007,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10ZM13.53,8.21s1.5,1.505,3.255,3.259a.75.75,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.74.74,0,0,1-.526.217.751.751,0,0,1-.534-1.278l1.977-1.977H7.755a.75.75,0,0,1,0-1.5h6.693L12.47,9.271a.745.745,0,0,1,.006-1.054.754.754,0,0,1,.53-.222.738.738,0,0,1,.524.215Z'
						transform='translate(-2.009 -2)'
						fill='#8f8f8f'
					/>
				</svg>
			</div>
		</div>
	);
};

export default withRouter(SingleTask);
