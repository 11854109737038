import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { Login as Authentication } from '@microsoft/mgt-react';

import { createNotification } from '../../utils/notificationManager';
import { authenticate, checkUser, checkTenant } from '../../actions/authentication';
import {
    APP_ROUTES,
    EMAIL_REGEX,
    LOCAL_STORAGE_KEYS,
    NOTIFICATION_TYPES,
} from '../../constants/common';
import '../../../sass/login.scss';

import companyLogo from '../../../assets/images/coremaxlogo-trans.png';
import backgroundVideo from '../../../assets/videos/video.mp4';
import Loader from "../atom/Loader";

import { DebounceInput } from 'react-debounce-input';

class Login extends React.Component {
    static propTypes = {
        authenticate: PropTypes.func.isRequired,
        checkUser: PropTypes.func.isRequired,
        checkTenant: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.loginRef = React.createRef();
        this.state = {
            manuallySelectTenant: false,
            email: '',
            buttonEnabled: false,
            showError: false,
            showDoesnotExistError: false,
            tenantName: ''
            //loading: localStorage.getItem(LOCAL_STORAGE_KEYS.OFFICE_365) === 'true',
        };


    }

    componentDidMount() {
        let url = window.location.href;
        url = url.split('.');

        let firstPart = url[0].replace('http://', '').replace('https://', '');
        console.log("********** ", firstPart);
        if (firstPart.indexOf('/') < 0 && firstPart !== 'usp' && firstPart !== 'staging' && firstPart !== 'dev' && firstPart!== 'web') {
            this.checkTenant(firstPart);
            this.setState({
                tenantName: firstPart
            });
        }
        else {
            if (firstPart === 'usp') {
                console.log("********** Prod Master");
                this.setState({
                    tenantName: 'Prod Master'
                });
            } else if (firstPart === 'staging') {
                console.log("********** Stage Master");
                this.setState({
                    tenantName: 'Stage Master'
                });
            } else if (firstPart === 'dev') {
                console.log("********** Dev Master");
                this.setState({
                    tenantName: 'Dev Master'
                });
            } else {
                console.log("********** ", firstPart);
                this.setState({
                    tenantName: firstPart
                });
            }

        }


        if (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.OFFICE_365,
                'false',
            );
            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
        }

        // if (localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID)) {
        //     this.setState({
        //         tenantid: localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID)
        //     }, () => {
        //         this.checkTenant(localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID));
        //     })
        // }
    }

    validate = (email) => {
        const pattern = new RegExp(EMAIL_REGEX);
        return pattern.test(email);
    };

    authenticate = () => {
        const { authenticate } = this.props;
        const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
        const request = { "email": user, "api_type": "react" };

        if (!this.validate(user)) {
            this.setState({ loading: false });
            createNotification(NOTIFICATION_TYPES.ERROR, 'Invalid Email');
            return;
        }

        authenticate(request)
            .then(res => {
                if (res.response.data.token) {
                    this.props.history.push(APP_ROUTES.DASHBOARD);
                } else {
                    createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                }
                window.location.reload();
            }).finally(() => {
                this.setState({ loading: false });
            });
    };

    loginInitiated = () => {
        //this.setState({ loading: true });
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.OFFICE_365,
            'true',
        );
    };

    loginCompleted = () => {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) {
            this.authenticate();
        }
    };

    checkTenant = (value) => {
        const { checkTenant } = this.props;
        const request = { "tenant": value, "api_type": "react" };
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.TENANT_ID,
            value,
        );
        this.setState({
            tenantid: value
        })
        checkTenant(request).then(res => {
            this.setState({
                tenatnSetInLocalStorage: true,
                showTenantDoesNotExists: false,
            })

            localStorage.setItem(
                LOCAL_STORAGE_KEYS.TENANT_FOUND,
                "1",
            );
        })
            .catch(err => {
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.TENANT_FOUND,
                    "0",
                );
                this.setState({
                    tenatnSetInLocalStorage: false,
                    showTenantDoesNotExists: true,
                })
            });
    }

    checkUser = (value) => {
        this.setState({
            email: value,
        }, () => {
            const { checkUser } = this.props;
            if (!this.validate(value)) {
                this.setState({ showError: true });
                return;
            }
            this.setState({ showError: false });
            const request = { "email": value, "api_type": "react" };
            checkUser(request)
                .then(res => {
                    if (res.response.data.token) {
                        this.setState({
                            buttonEnabled: true,
                            showDoesnotExistError: false,
                        });
                    } else {
                        this.setState({
                            buttonEnabled: false,
                            showDoesnotExistError: true,
                        });
                    }
                })
                .catch(err => {
                    if (err?.error?.response?.data?.message === 'Email not Exist') {
                        this.setState({
                            buttonEnabled: false,
                            showDoesnotExistError: true,
                        });
                    }
                });
        });
    };

    render() {
        return (
            <>
                {this.state.loading && (
                    <>
                        <Loader width='100%' />
                        {/* <Authentication
                            className={this.state.buttonEnabled ? 'ms-login' : 'ms-login disabled'}
                            ref={this.loginRef}
                            loginCompleted={this.loginCompleted}
                            loginInitiated={this.loginInitiated}
                            loginFailed={this.loginFailed}
                            hidden
                        /> */}
                    </>
                )}
                {!this.state.loading && (
                    <>
                        <video autoPlay loop muted className="video">
                            <source src={backgroundVideo} type='video/mp4' />
                        </video>
                        <div className="login-content">
                            <img
                                src={companyLogo}
                                alt="Coremax"
                            />
                            <br />
                            <div className="login-input-container">
                                {this.state.manuallySelectTenant && (
                                    <>
                                        <DebounceInput type="text"
                                            debounceTimeout={500}
                                            onChange={event => this.checkTenant(event.target.value)}
                                            className={this.state.tenatnSetInLocalStorage ? "tenant-input success" : "tenant-input"}
                                            placeholder="Tenant Name"
                                            value={this.state.tenantid}
                                        />
                                        <div className={"error-message mt-2"}>
                                            {this.state.showTenantDoesNotExists && (
                                                <span style={{
                                                    fontSize: '16px',
                                                    fontWeight: '600',
                                                    color: 'red',
                                                    textAlign: "center",
                                                }}>Tenant does not exists (User will be logged on to the master tenant)</span>
                                            )}
                                        </div>
                                    </>
                                )}

                                {!this.state.manuallySelectTenant && (
                                    <>
                                        <div style={{ 'position': 'absolute', 'bottom': '0px', 'right': '10px' }}>
                                            <button type="button" class="btn btn-secondary mr-4 disabled">
                                                Client: <span class="badge badge-light">{this.state.tenantName}</span>
                                            </button>
                                            {this.state.showTenantDoesNotExists && (
                                                <button type="button" class="btn btn-primary"
                                                    onClick={event => this.setState({ manuallySelectTenant: true })}>
                                                    Change Client Manually
                                                </button>
                                            )}
                                            <div className={"error-message mt-2"}>
                                                {this.state.showTenantDoesNotExists && (
                                                    <span style={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                        color: 'red',
                                                        textAlign: "center",
                                                    }}>Tenant does not exists (User will be logged on to the master tenant)</span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}

                                <DebounceInput type="email"
                                    onChange={event => this.checkUser(event.target.value)}
                                    className="email-input"
                                    debounceTimeout={500}
                                    placeholder="Email Address">
                                </DebounceInput>

                                <div className={"error-message mt-2"}>
                                    {this.state.showError && (
                                        <span style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: 'red',
                                            textAlign: "center",
                                        }}>Please enter a valid email</span>
                                    )}
                                    {this.state.showDoesnotExistError && (
                                        <span style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: 'red',
                                            textAlign: "center",
                                        }}>Email does not exist</span>
                                    )}
                                </div>
                            </div>
                            <br />
                            {/* <Authentication
                                className={this.state.buttonEnabled ? 'ms-login' : 'ms-login login-button-disabled'}
                                ref={this.loginRef}
                                loginCompleted={this.loginCompleted}
                                loginInitiated={this.loginInitiated}
                                loginFailed={this.loginFailed}
                            /> */}
                        </div>
                    </>
                )
                }
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            checkUser,
            checkTenant,
            authenticate,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(Login));