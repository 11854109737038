import { useState, useRef, forwardRef, useImperativeHandle } from "react";

const FileInput = forwardRef(({ callback, accept, defaultText }, ref) => {
	const [fileName, setFileName] = useState("");
	const fileInputRef = useRef(null);

	useImperativeHandle(ref, () => ({
		reset: () => {
			setFileName("");
			if (fileInputRef.current) {
				fileInputRef.current.value = "";
			}
		},
	}));

	return (
		<div
			className='file-input-holder col-1-1 pos-rel'
			style={{ background: "white" }}>
			{fileName ? fileName : defaultText}
			<input
			    className="primary"
				type='file'
				accept={accept}
				onChange={(e) => {
					setFileName(e.target.files[0].name);
					callback(e.target.files[0]);
				}}
				ref={fileInputRef}
			/>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 24 24'
				fill='none'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				class='feather feather-file'
				width='24'
				height='24'>
				<path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z' />
				<polyline points='14 2 14 8 20 8' />
			</svg>
		</div>
	);
});

export default FileInput;
