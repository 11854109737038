import ButtonLightTextIcon from "./ButtonLightTextIcon";
import ButtonTextIcon from "./ButtonTextIcon";


const ConfirmationModal = ({ message, onConfirm, setShow }) => {

    return (
        <section id='opl_coremax'>
            <section
                style={{
                    position: "fixed",
                    inset: 0,
                    zIndex: 9999,
                    backgroundColor: "rgba(255, 255, 255, 0.86)",
                }}>
                <section
                    className='col-1-1 flex center-center'
                    style={{
                        height: "100vh",
                        width: "100vw",
                    }}>
                    <div
                        style={{
                            width: "486px",
                            padding: "24px",
                            height: "auto",
                            maxHeight: "80vh",
                            border: "solid 1px #f0f0f0",
                            background: "white",
                            borderRadius: "8px",
                            margin: "0 auto",
                            boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
                        }}>
                        <div
                            className='col-1-1 flex center-left'
                            style={{
                                borderBottom: "solid 1px #f0f0f0",
                                paddingBottom: "12px",
                                marginBottom: "16px",
                            }}>
                            <h6
                                className='color-primary f-w-300 opl-tooltip'
                                data-tooltip='View entire history of the activity below.'>
                                Confirmation
                            </h6>
                            <div
                                className='mar-l-a cursor-pointer opl-tooltip'
                                data-tooltip='Close to return to the previous section.'
                                onClick={() => setShow(false)}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'>
                                    <path
                                        d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
                                        fill='#283f54'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div
                        // style={{
                        // 	height: "492px",
                        // 	overflowY: "auto",
                        // 	background: "white",
                        // }}
                        >
                            <section>
                                <p>{message}</p>
                            </section>
                        </div>
                        <div
                            className='col-1-1 flex center-left'
                            style={{
                                marginTop: "16px",
                                borderTop: "solid 1px #f0f0f0",
                                paddingTop: "16px",
                            }}>
                            <ButtonLightTextIcon
                                title='Return Back'
                                onClick={() => setShow(false)}
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'>
                                        <path
                                            d='M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1ZM7.172,12.5l4.608,3.763L11.1,17,5,12l6.112-5,.666.753L7.174,11.5H19v1Z'
                                            fillRule='evenodd'
                                        />
                                    </svg>
                                }
                            />

                            <div style={{ marginLeft: "auto" }}>

                            </div>
                            <ButtonTextIcon
                                title='Confirm'
                                onClick={() => { setShow(); onConfirm(); }}
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'>
                                        <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
                                    </svg>
                                }
                            />

                        </div>
                    </div>
                </section>
            </section>
        </section>
    );
};

export default ConfirmationModal;