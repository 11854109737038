import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useState } from "react";
import {
	API_URL,
	SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import Accordion from "../../components/common/Accordion";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";

const AddForm = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");

	const initialValues = {
		firstName: '',
		lastName: '',
		middleName: '',
		gender: '',
		workPhone: '',
		homePhone: '',
		cellPhone: '',
		fax: '',
		accountNumber: '',
		email: '',
		dob: '',
		ssn: '',
		address1: '',
		address2: '',
		zipCode: '',
		state: '',
		city: '',
		country: '',
		notes: '',
		primaryBillableType: '',
		primaryPayerID: '',
		primaryInsuranceName: '',
		primaryMasterPayerID: '',
		primaryInsuranceTypeCode: '',
		primaryInsurancePhone: '',
		primaryInsuranceFax: '',
		primaryInsuranceAddress: '',
		primarySubscriberFirstName: '',
		primarySubscriberLastName: '',
		primaryMemberNumber: '',
		primaryGroupNumber: '',
		primarySubscriberDOB: '',
		guarantorFirstName: '',
		guarantorLastName: '',
		guarantorAddressLine1: '',
		guarantorAddressLine2: '',
		guarantorZipCode: '',
		guarantorState: '',
		guarantorCity: '',
		guarantorSpouseOrParentsName: '',
		guarantorSpousePartnerPhone: '',
		guarantorRelationshipToPatient: '',
		secondaryInsuranceName: '',
		secondaryPayerID: '',
		secondaryMasterPayerID: '',
		secondaryInsuranceTypeCode: '',
		secondaryInsurancePhone: '',
		secondaryInsuranceFax: '',
		secondaryInsuranceAddress: '',
		secondarySubscriberFirstName: '',
		secondarySubscriberLastName: '',
		secondaryMemberNumber: '',
		secondaryGroupNumber: '',
		secondarySubscriberDOB: '',
		tertiaryInsuranceName: '',
		tertiaryPayerID: '',
		tertiaryMasterPayerID: '',
		tertiaryInsuranceTypeCode: '',
		tertiaryInsurancePhone: '',
		tertiaryInsuranceFax: '',
		tertiaryInsuranceAddress: '',
		tertiarySubscriberFirstName: '',
		tertiarySubscriberLastName: '',
		tertiaryMemberNumber: '',
		tertiaryGroupNumber: '',
		tertiarySubscriberDOB: '', 
		patientConditionRelatedTo: '',
		employerName: '',
		illnessIndicator: '',
		dateOfInjury: '',
	};

	const validationSchema = Yup.object({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		gender: Yup.string().required('Gender is required'),
		workPhone: Yup.string().required('Phone Number (Work) is required'),
		cellPhone: Yup.string().required('Cell Phone is required'),
		accountNumber: Yup.string().required('Account # is required'),
		dob: Yup.date().required('DOB is required'),
		ssn: Yup.string().required('Social Security number is required'),
		address1: Yup.string().required('Address Line 1 is required'),
		zipCode: Yup.string().required('Zip Code is required'),
		city: Yup.string().required('City is required'),
		country: Yup.string().required('Country is required'),
		notes: Yup.string().required('Notes are required'),
	});

	function convertToMMDDYYYY(dateString) {
		const date = new Date(dateString);
		const formattedDate =
			("0" + (date.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + date.getDate()).slice(-2) +
			"/" +
			date.getFullYear();
		return formattedDate;
	}

	function convertTo12HourFormat(timeString) {
		const [hours, minutes] = timeString.split(":");
		const hour = parseInt(hours, 10);
		const period = hour >= 12 ? "PM" : "AM";
		const convertedHour = hour % 12 || 12;
		return `${("0" + convertedHour).slice(-2)}:${minutes} ${period}`;
	}

	const createPatient = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = JSON.stringify({
				user_id: JSON.parse(localStorage.getItem("LOGGED_IN_USER")).id,
				event_surgeon: values.event_surgeon + "",
				file: values.file || "",
				file_type: values.file ? values.file_type : "",
				patient_id: values.patient_id + "",
				paytoadmin_id: values.paytoadmin_id + "",
				modified_by: JSON.parse(localStorage.getItem("LOGGED_IN_USER"))
					.id,
				patient_name: values.patient_name,
				procedure_id: values.procedure_id
					.map((item) => item.val)
					.join(","),
				event_facility: values.event_facility + "",
				event_surgical_assitant: values.event_surgical_assitant + "",
				account_number: values.account_number + "",
				placeOfService: values.placeOfService + "",
				event_start_date: convertToMMDDYYYY(values.event_start_date),
				event_end_date: convertToMMDDYYYY(values.event_end_date),
				event_start_time: convertTo12HourFormat(
					values.event_start_time
				),
				event_end_time: convertTo12HourFormat(values.event_end_time),
				event_type: values.event_type + "",
				event_flags: values.event_flags + "",
				admission_date: convertToMMDDYYYY(values.admission_date),
			});

			console.log("Payload:::", payload);

			const resp = await fetch(API_URL + `/api/1.0.0/patient`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": SYSTEM_TOKEN,
				},
				body: payload,
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					return response.result.responseCode === 200 ? true : false;
				} else {
					return false;
				}
			}
		} catch (e) {
			console.log("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const Label = ({ title, required }) => {
		return (
			<p
				className='color-primary f-w-600'
				style={{ paddingBottom: "5px" }}>
				{title}
				{required && (
					<span style={{ color: "red", marginLeft: "4px" }}>*</span>
				)}
			</p>
		);
	};
 
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await createPatient(values);
				if (api) {
					resetForm();
					setStatus(
						"You have successfully created an patient, thank you."
					);
				} else {
					setStatus("Something went wrong, please try again later.");
				}
			}}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td width='50%'>
										<Label title='First Name' required={true} />
										<Field
											type='text'
											placeholder='Enter First Name '
											name='firstName'
											className={`col-1-1 primary ${errors.firstName &&
												touched.firstName
												? "error"
												: ""
												}`}
										/>
									</td>
									<td  >
										<Label title='Last Name' required={true} />
										<Field
											type='text'
											placeholder='Enter Last Name '
											name='lastName'
											className={`col-1-1 primary ${errors.lastName &&
												touched.lastName
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Middle Name' />
										<Field
											type='text'
											placeholder='Enter Middle Name '
											name='middleName'
											className={`col-1-1 primary ${errors.middleName &&
												touched.middleName
												? "error"
												: ""
												}`}
										/>
									</td>
									<td >
										<Label title='Gender' required={true} />
										<Field
											as='select'
											placeholder='Select Gender'
											name='gender'
											className={`col-1-1 primary ${errors.gender &&
												touched.gender
												? "error"
												: ""
												}`}
										>
											<option value="">Select Gender</option>
											{["Male", "Female", "Other"].map((d, i) => (
												<option value={d} key={i}> {d}</option>
											))}
										</Field>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Phone Number (Work)' required={true} />
										<Field
											type='text'
											placeholder='Enter Phone Number (Work)'
											name='workPhone'
											className={`col-1-1 primary ${errors.workPhone &&
												touched.workPhone
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='Phone Number (Home)' />
										<Field
											type='text'
											placeholder='Enter Phone Number (Home)'
											name='homePhone'
											className={`col-1-1 primary ${errors.homePhone &&
												touched.homePhone
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Cell Phone' required={true} />
										<Field
											type='text'
											placeholder='Enter Cell Phone'
											name='cellPhone'
											className={`col-1-1 primary ${errors.cellPhone &&
												touched.cellPhone
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='Fax' />
										<Field
											type='text'
											placeholder='Enter Fax'
											name='fax'
											className={`col-1-1 primary ${errors.fax &&
												touched.fax
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Account #' required={true} />
										<Field
											type='text'
											placeholder='Enter Account #'
											name='accountNumber'
											className={`col-1-1 primary ${errors.accountNumber &&
												touched.accountNumber
												? "error"
												: ""
												}`}
										/>
									</td>
									<td >
										<Label title='Email Address' />
										<Field
											type='text'
											placeholder='Enter Email Address'
											name='email'
											className={`col-1-1 primary ${errors.email &&
												touched.email
												? "error"
												: ""
												}`}
										/>
									</td>

								</tr>
								<tr>
									<td width='50%'>
										<Label title='DOB(MM/DD/YYYY)' required={true} />
										<Field
											type='date'
											placeholder='Enter DOB(MM/DD/YYYY)'
											name='dob'
											className={`col-1-1 primary ${errors.dob &&
												touched.dob
												? "error"
												: ""
												}`}
										/>
									</td>
									<td  >
										<Label title='Social Security number' required={true} />
										<Field
											type='text'
											placeholder='Enter Social Security number'
											name='ssn'
											className={`col-1-1 primary ${errors.ssn &&
												touched.ssn
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan="2" >
										<Label title='Address Line 1' required={true} />
										<textarea
											type='textarea'
											placeholder='Enter Address Line 1'
											name='address1'
											className={`col-1-1 primary ${errors.address1 &&
												touched.address1
												? "error"
												: ""
												}`}
										></textarea>
									</td>
								</tr>
								<tr>
									<td colSpan="2" >
										<Label title='Address Line 2' required={true} />
										<textarea
											type='textarea'
											placeholder='Enter Address Line 2'
											name='address2'
											className={`col-1-1 primary ${errors.address2 &&
												touched.address2
												? "error"
												: ""
												}`}
										></textarea>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Zip Code' required={true} />
										<Field
											type='text'
											placeholder='Enter Zip Code'
											name='zipCode'
											className={`col-1-1 primary ${errors.zipCode &&
												touched.zipCode
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='State' />
										<Field
											type='text'
											placeholder='Enter State'
											name='state'
											className={`col-1-1 primary ${errors.state &&
												touched.state
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='City' required={true} />
										<Field
											type='text'
											placeholder='Enter City'
											name='state'
											className={`col-1-1 primary ${errors.state &&
												touched.state
												? "error"
												: ""
												}`}
										/>
									</td>
									<td >
										<Label title='Country' required={true} />
										<Field
											as="select"
											placeholder='Enter Country'
											name='country'
											className={`col-1-1 primary ${errors.country &&
												touched.country
												? "error"
												: ""
												}`}
										>
											<option value="usa">United States</option>
										</Field>
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<Label title='Notes' required={true} />
										<textarea
											type='textarea'
											placeholder='Enter Notes'
											name='notes'
											className={`col-1-1 primary ${errors.notes &&
												touched.notes
												? "error"
												: ""
												}`}
										></textarea>
									</td>
								</tr>
								<tr>
									<td colSpan="4" >
										<Accordion items={[
											{
												title: (
													<span>
														<svg
															width="15"
															height="15"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															className="lucide lucide-shield w-4 h-4 mr-2"
															data-id="55"
														>
															<path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
														</svg>
														<span>Primary Insurance Information</span>
													</span>
												), content: (<div className="pad-20" >
													<table>
														<tbody>
															<tr>
																<td colSpan="2" >
																	<ButtonLightTextIcon
																		title="Remove Payer"
																	/>
																</td>
															</tr>
															<tr>
																<td width='50%'>
																	<Label title='Billable / Non-Billable / Non-Billable Commercial' />
																	<Field
																		type='text'
																		placeholder='Select'
																		name='primaryBillableType'
																		className={`col-1-1 primary ${errors.primaryBillableType &&
																			touched.primaryBillableType
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td width='50%'>
																	<Label title='Primary Electronic Payer ID' />
																	<Field
																		type='text'
																		placeholder='Enter Primary Electronic Payer ID'
																		name='primaryPayerID'
																		className={`col-1-1 primary ${errors.primaryPayerID &&
																			touched.primaryPayerID
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td colSpan="2">
																	<Label title='Primary Insurance Name' />
																	<Field
																		type='text'
																		placeholder='Enter Primary Insurance Name'
																		name='primaryInsuranceName'
																		className={`col-1-1 primary ${errors.primaryInsuranceName &&
																			touched.primaryInsuranceName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Master Payer ID' />
																	<Field
																		type='text'
																		placeholder='Enter Master Payer ID'
																		name='primaryMasterPayerID'
																		className={`col-1-1 primary ${errors.primaryMasterPayerID &&
																			touched.primaryMasterPayerID
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td  >
																	<Label title='Insurance Type Code' />
																	<Field
																		type='text'
																		placeholder='Enter Insurance Type Code'
																		name='primaryInsuranceTypeCode'
																		className={`col-1-1 primary ${errors.primaryInsuranceTypeCode &&
																			touched.primaryInsuranceTypeCode
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Primary Insurance Phone' />
																	<Field
																		type='text'
																		placeholder='Enter Primary Insurance Phone'
																		name='primaryInsurancePhone'
																		className={`col-1-1 primary ${errors.primaryInsurancePhone &&
																			touched.primaryInsurancePhone
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Primary Insurance Fax' />
																	<Field
																		type='text'
																		placeholder='Enter Primary Insurance Fax'
																		name='primaryInsuranceFax'
																		className={`col-1-1 primary ${errors.primaryInsuranceFax &&
																			touched.primaryInsuranceFax
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td colSpan="2" >
																	<Label title='Primary Insurance Address' />
																	<Field
																		as="textarea"
																		placeholder='Enter Primary Insurance Address'
																		name='primaryInsuranceAddress'
																		className={`col-1-1 primary ${errors.primaryInsuranceAddress &&
																			touched.primaryInsuranceAddress
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td>
																	<ButtonTextIcon title="Fill Form With Patient Info" />
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Subscriber First Name' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber First Name'
																		name='primarySubscriberFirstName'
																		className={`col-1-1 primary ${errors.primarySubscriberFirstName &&
																			touched.primarySubscriberFirstName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Subscriber Last Name' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber Last Name'
																		name='primarySubscriberLastName'
																		className={`col-1-1 primary ${errors.primarySubscriberLastName &&
																			touched.primarySubscriberLastName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Member Number' />
																	<Field
																		type='text'
																		placeholder='Enter Member Number'
																		name='primaryMemberNumber'
																		className={`col-1-1 primary ${errors.primaryMemberNumber &&
																			touched.primaryMemberNumber
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Group Number' />
																	<Field
																		type='text'
																		placeholder='Enter Group Number'
																		name='primaryGroupNumber'
																		className={`col-1-1 primary ${errors.primaryGroupNumber &&
																			touched.primaryGroupNumber
																			? "error"
																			: ""
																			}`}
																	/>
																</td>

															</tr>
															<tr>
																<td width="50%">
																	<Label title='Subscriber DOB' />
																	<Field
																		type='date'
																		placeholder='Enter Subscriber DOB'
																		name='primarySubscriberDOB'
																		className={`col-1-1 primary ${errors.primarySubscriberDOB &&
																			touched.primarySubscriberDOB
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
														</tbody>
													</table>
												</div>)
											},
											{
												title: (
													<span>
														<svg
															width="15"
															height="15"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															className="lucide lucide-shield w-4 h-4 mr-2"
															data-id="55"
														>
															<path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
														</svg>
														<span>Guarantor Information</span>
													</span>
												), content: (<div className="pad-20" >
													<table>
														<tbody>
															<tr>
																<td colSpan="2" >
																	<ButtonLightTextIcon
																		title="Remove Payer"
																	/>
																</td>
															</tr>
															<tr>
																<td width='50%'>
																	<Label title='Guarantor First Name' />
																	<Field
																		type='text'
																		placeholder='Enter Guarantor First Name'
																		name='guarantorFirstName'
																		className={`col-1-1 primary ${errors.guarantorFirstName &&
																			touched.guarantorFirstName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td width='50%'>
																	<Label title='Guarantor Last Name' />
																	<Field
																		type='text'
																		placeholder='Enter Guarantor Last Name'
																		name='guarantorLastName'
																		className={`col-1-1 primary ${errors.guarantorLastName &&
																			touched.guarantorLastName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width='50%'>
																	<Label title='Address Line 1' />
																	<Field
																		type='text'
																		placeholder='Enter Address Line 1'
																		name='guarantorAddressLine1'
																		className={`col-1-1 primary ${errors.guarantorAddressLine1 &&
																			touched.guarantorAddressLine1
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td width='50%'>
																	<Label title='Address Line 2' />
																	<Field
																		type='text'
																		placeholder='Address Line 2'
																		name='guarantorAddressLine2'
																		className={`col-1-1 primary ${errors.guarantorAddressLine2 &&
																			touched.guarantorAddressLine2
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width='50%'>
																	<Label title='Zip Code' />
																	<Field
																		type='text'
																		placeholder='Enter Zip Code'
																		name='guarantorZipCode'
																		className={`col-1-1 primary ${errors.guarantorZipCode &&
																			touched.guarantorZipCode
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td width='50%'>
																	<Label title='State' />
																	<Field
																		type='text'
																		placeholder='Enter State'
																		name='guarantorState'
																		className={`col-1-1 primary ${errors.guarantorState &&
																			touched.guarantorState
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width='50%'>
																	<Label title='City' />
																	<Field
																		type='text'
																		placeholder='Enter City'
																		name='guarantorCity'
																		className={`col-1-1 primary ${errors.guarantorCity &&
																			touched.guarantorCity
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td width='50%'>
																	<Label title="Spouse or Partner's Name/Parents Name (if patient is a minor)" />
																	<Field
																		type='text'
																		placeholder="Enter Spouse or Partner's Name/Parents Name (if patient is a minor)"
																		name='guarantorSpouseOrParentsName'
																		className={`col-1-1 primary ${errors.guarantorSpouseOrParentsName &&
																			touched.guarantorSpouseOrParentsName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width='50%'>
																	<Label title='Spouse, Partner or Partners Phone' />
																	<Field
																		type='text'
																		placeholder='Enter Spouse, Partner or Partners Phone'
																		name='guarantorSpousePartnerPhone'
																		className={`col-1-1 primary ${errors.guarantorSpousePartnerPhone &&
																			touched.guarantorSpousePartnerPhone
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td width='50%'>
																	<Label title='Relationship To Patient' />
																	<Field
																		type='text'
																		placeholder='Enter Relationship To Patient'
																		name='guarantorRelationshipToPatient'
																		className={`col-1-1 primary ${errors.guarantorRelationshipToPatient &&
																			touched.guarantorRelationshipToPatient
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
														</tbody></table></div>)
											},
											{
												title: (
													<span>
														<svg
															width="15"
															height="15"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															className="lucide lucide-shield w-4 h-4 mr-2"
															data-id="55"
														>
															<path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
														</svg>
														<span>Secondary Insurance Information</span>
													</span>
												), content: (<div className="pad-20" >
													<table>
														<tbody>
															<tr>
																<td colSpan="2" >
																	<ButtonLightTextIcon
																		title="Remove Payer"
																	/>
																</td>
															</tr>
															<tr>
																<td>
																	<Label title='Secondary Insurance Name' />
																	<Field
																		type='text'
																		placeholder='Enter Secondary Insurance Name'
																		name='secondaryInsuranceName'
																		className={`col-1-1 primary ${errors.secondaryInsuranceName &&
																			touched.secondaryInsuranceName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td>
																	<Label title='Secondary Payer ID' />
																	<Field
																		type='text'
																		placeholder='Enter Secondary Payer ID'
																		name='secondaryPayerID'
																		className={`col-1-1 primary ${errors.secondaryPayerID &&
																			touched.secondaryPayerID
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Master Payer ID' />
																	<Field
																		type='text'
																		placeholder='Enter Master Payer ID'
																		name='secondaryMasterPayerID'
																		className={`col-1-1 primary ${errors.secondaryMasterPayerID &&
																			touched.secondaryMasterPayerID
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td  >
																	<Label title='Insurance Type Code' />
																	<Field
																		type='text'
																		placeholder='Enter Insurance Type Code'
																		name='secondaryInsuranceTypeCode'
																		className={`col-1-1 primary ${errors.secondaryInsuranceTypeCode &&
																			touched.secondaryInsuranceTypeCode
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Secondary Insurance Phone' />
																	<Field
																		type='text'
																		placeholder='Enter Secondary Insurance Phone'
																		name='secondaryInsurancePhone'
																		className={`col-1-1 primary ${errors.secondaryInsurancePhone &&
																			touched.secondaryInsurancePhone
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Secondary Insurance Fax' />
																	<Field
																		type='text'
																		placeholder='Enter Secondary Insurance Fax'
																		name='secondaryInsuranceFax'
																		className={`col-1-1 primary ${errors.secondaryInsuranceFax &&
																			touched.secondaryInsuranceFax
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td colSpan="2" >
																	<Label title='Secondary Insurance Address' />
																	<Field
																		as="textarea"
																		placeholder='Enter Secondary Insurance Address'
																		name='secondaryInsuranceAddress'
																		className={`col-1-1 primary ${errors.secondaryInsuranceAddress &&
																			touched.secondaryInsuranceAddress
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td>
																	<ButtonTextIcon title="Fill Form With Patient Info" />
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Subscriber First Name' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber First Name'
																		name='secondarySubscriberFirstName'
																		className={`col-1-1 primary ${errors.secondarySubscriberFirstName &&
																			touched.secondarySubscriberFirstName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Subscriber Last Name' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber Last Name'
																		name='secondarySubscriberLastName'
																		className={`col-1-1 primary ${errors.secondarySubscriberLastName &&
																			touched.secondarySubscriberLastName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Member Number' />
																	<Field
																		type='text'
																		placeholder='Enter Member Number'
																		name='secondaryMemberNumber'
																		className={`col-1-1 primary ${errors.secondaryMemberNumber &&
																			touched.secondaryMemberNumber
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Group Number' />
																	<Field
																		type='text'
																		placeholder='Enter Group Number'
																		name='secondaryGroupNumber'
																		className={`col-1-1 primary ${errors.secondaryGroupNumber &&
																			touched.secondaryGroupNumber
																			? "error"
																			: ""
																			}`}
																	/>
																</td>

															</tr>
															<tr>
																<td width="50%">
																	<Label title='Subscriber DOB' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber DOB'
																		name='secondarySubscriberDOB'
																		className={`col-1-1 primary ${errors.secondarySubscriberDOB &&
																			touched.secondarySubscriberDOB
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
														</tbody>
													</table>
												</div>)
											},
											{
												title: (
													<span>
														<svg
															width="15"
															height="15"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															className="lucide lucide-shield w-4 h-4 mr-2"
															data-id="55"
														>
															<path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
														</svg>
														<span>Tertiary Insurance Information</span>
													</span>
												), content: (<div className="pad-20" >
													<table>
														<tbody>
															<tr>
																<td colSpan="2" >
																	<ButtonLightTextIcon
																		title="Remove Payer"
																	/>
																</td>
															</tr>
															<tr>
																<td>
																	<Label title='Tertiary Insurance Name' />
																	<Field
																		type='text'
																		placeholder='Enter Tertiary Insurance Name'
																		name='tertiaryInsuranceName'
																		className={`col-1-1 primary ${errors.tertiaryInsuranceName &&
																			touched.tertiaryInsuranceName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td>
																	<Label title='Tertiary Payer ID' />
																	<Field
																		type='text'
																		placeholder='Enter Tertiary Payer ID'
																		name='tertiaryPayerID'
																		className={`col-1-1 primary ${errors.tertiaryPayerID &&
																			touched.tertiaryPayerID
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Master Payer ID' />
																	<Field
																		type='text'
																		placeholder='Enter Master Payer ID'
																		name='tertiaryMasterPayerID'
																		className={`col-1-1 primary ${errors.tertiaryMasterPayerID &&
																			touched.tertiaryMasterPayerID
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td  >
																	<Label title='Insurance Type Code' />
																	<Field
																		type='text'
																		placeholder='Enter Insurance Type Code'
																		name='tertiaryInsuranceTypeCode'
																		className={`col-1-1 primary ${errors.tertiaryInsuranceTypeCode &&
																			touched.tertiaryInsuranceTypeCode
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Tertiary Insurance Phone' />
																	<Field
																		type='text'
																		placeholder='Enter Tertiary Insurance Phone'
																		name='tertiaryInsurancePhone'
																		className={`col-1-1 primary ${errors.tertiaryInsurancePhone &&
																			touched.tertiaryInsurancePhone
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Tertiary Insurance Fax' />
																	<Field
																		type='text'
																		placeholder='Enter Tertiary Insurance Fax'
																		name='tertiaryInsuranceFax'
																		className={`col-1-1 primary ${errors.tertiaryInsuranceFax &&
																			touched.tertiaryInsuranceFax
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td colSpan="2" >
																	<Label title='Tertiary Insurance Address' />
																	<Field
																		as="textarea"
																		placeholder='Enter Tertiary Insurance Address'
																		name='tertiaryInsuranceAddress'
																		className={`col-1-1 primary ${errors.tertiaryInsuranceAddress &&
																			touched.tertiaryInsuranceAddress
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td>
																	<ButtonTextIcon title="Fill Form With Patient Info" />
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Subscriber First Name' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber First Name'
																		name='tertiarySubscriberFirstName'
																		className={`col-1-1 primary ${errors.tertiarySubscriberFirstName &&
																			touched.tertiarySubscriberFirstName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Subscriber Last Name' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber Last Name'
																		name='tertiarySubscriberLastName'
																		className={`col-1-1 primary ${errors.tertiarySubscriberLastName &&
																			touched.tertiarySubscriberLastName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Member Number' />
																	<Field
																		type='text'
																		placeholder='Enter Member Number'
																		name='tertiaryMemberNumber'
																		className={`col-1-1 primary ${errors.tertiaryMemberNumber &&
																			touched.tertiaryMemberNumber
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td >
																	<Label title='Group Number' />
																	<Field
																		type='text'
																		placeholder='Enter Group Number'
																		name='tertiaryMemberNumber'
																		className={`col-1-1 primary ${errors.tertiaryMemberNumber &&
																			touched.tertiaryMemberNumber
																			? "error"
																			: ""
																			}`}
																	/>
																</td>

															</tr>
															<tr>
																<td width="50%">
																	<Label title='Subscriber DOB' />
																	<Field
																		type='text'
																		placeholder='Enter Subscriber DOB'
																		name='tertiarySubscriberDOB'
																		className={`col-1-1 primary ${errors.tertiarySubscriberDOB &&
																			touched.tertiarySubscriberDOB
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
														</tbody>
													</table>
												</div>)
											},
											{
												title: (
													<span>
														<svg
															width="15"
															height="15"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															className="lucide lucide-shield w-4 h-4 mr-2"
															data-id="55"
														>
															<path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
														</svg>
														<span>Worker's Comp or Other Property & Casualty Claim Details (For Event ID)</span>
													</span>
												), content: (<div className="pad-20" >
													<table>
														<tbody>
															<tr>
																<td>
																	<Label title='Patient Condition Related To' />
																	<Field
																		type='text'
																		placeholder='Select Patient Condition Related To'
																		name='patientConditionRelatedTo'
																		className={`col-1-1 primary ${errors.patientConditionRelatedTo &&
																			touched.patientConditionRelatedTo
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td>
																	<Label title='Employer Name' />
																	<Field
																		type='text'
																		placeholder='Enter Employer Name'
																		name='employerName'
																		className={`col-1-1 primary ${errors.employerName &&
																			touched.employerName
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
															<tr>
																<td width="50%">
																	<Label title='Date of Injury' />
																	<Field
																		type='text'
																		placeholder='Enter Date of Injury'
																		name='dateOfInjury'
																		className={`col-1-1 primary ${errors.dateOfInjury &&
																			touched.dateOfInjury
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
																<td  >
																	<Label title='Illness Indicator' />
																	<Field
																		type='text'
																		placeholder='Select Illness Indicator'
																		name='illnessIndicator'
																		className={`col-1-1 primary ${errors.illnessIndicator &&
																			touched.illnessIndicator
																			? "error"
																			: ""
																			}`}
																	/>
																</td>
															</tr>
														</tbody>
													</table>
												</div>)
											},
										]} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Return Back'
							onClick={() => history.push("/patients")}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19.995'
									height='19.996'
									viewBox='0 0 19.995 19.996'>
									<path
										d='M12.017,2a10,10,0,1,1-10,10,10,10,0,0,1,10-10Zm0,1.5a8.5,8.5,0,1,0,8.5,8.5,8.5,8.5,0,0,0-8.5-8.5ZM10.489,8.21s-1.5,1.505-3.255,3.259a.75.75,0,0,0,0,1.061c1.753,1.754,3.254,3.258,3.254,3.258a.74.74,0,0,0,.526.217.751.751,0,0,0,.534-1.278L9.571,12.75h6.693a.75.75,0,0,0,0-1.5H9.571l1.978-1.979a.745.745,0,0,0-.006-1.054.754.754,0,0,0-.53-.222.738.738,0,0,0-.524.215Z'
										transform='translate(-2.019 -1.995)'
									/>
								</svg>
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Create Now'
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='19.995'
										height='19.996'
										viewBox='0 0 19.995 19.996'>
										<path
											d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
											transform='translate(-2.014 -1.995)'
										/>
									</svg>
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default AddForm;
