import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import PaginatedDropdownPHP from "../../components/common/ui/PaginatedDropdownPHP";
import {
	API_URL,
	BILL_STATUS_OPTTIONS,
	CLAIM_STATUS_DROP_DOWN_OPTIONS,
	HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS,
	LARAVEL_API_URL,
} from "../../opl-utilities/constants";
import { withRouter } from "react-router-dom";
import { getLogInUserDetails } from "../../opl-utilities/helpers";

const Label = ({ title }) => {
	return (
		<p
			className='color-primary f-w-300 l-h-1'
			style={{ fontSize: "12px", paddingBottom: "6px" }}>
			&nbsp;{title}
		</p>
	);
};

const TableFilter = ({ history, location, resetFilters }) => {
	const queryParams = new URLSearchParams(location.search);
	const role = getLogInUserDetails("type");
	const handleQueryParamChange = (key, value) => {
		queryParams.delete("id");
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	const DROPDOWNS = [
		{
			title: "Patients",
			url: "/patients",
			param: "patient",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o.firstName} ${o.lastName}`,
			placeholder: "Search patient",
		},
		{
			title: "Practices",
			url: "/practice",
			param: "practice",
			mapFunc: mapOptions,
			labelFunc: (o) => o.payToName,
			placeholder: "Search practice",
		},
		{
			title: "Facilities",
			url: "/facility",
			param: "facility",
			mapFunc: mapOptions,
			labelFunc: (o) => o.facilityName,
			placeholder: "Search facility",
		},
		{
			title: "Insurances",
			url: "/insurances",
			param: "insurance",
			mapFunc: mapOptions,
			labelFunc: (o) => o.description,
			placeholder: "Search insurance",
		},
		{
			title: "Assistants",
			url: "/assistants",
			param: "assistant",
			mapFunc: mapOptions,
			labelFunc: (o) => o.fullName,
			placeholder: "Search assistants",
		},
	];


	return (
		<div className='col-1-1 flex center-left filters'>
			{ 
				role === "admin" &&
				<div>
						<Label title="Department" />
						<PaginatedDropdown
						    datakey="departments"
							url={`${API_URL}/api/1.0.0/department`}
							selectedValue={
								queryParams.get("department")
									? JSON.parse(queryParams.get("department"))
									: null
							}
							onChange={(v) =>
								handleQueryParamChange(
									"department",
									v ? JSON.stringify(v) : null
								)
							}
							mapOptions={(records) =>
								mapOptions(records, "id",  (o) => `${o.name}`)
							}
							placeHolder="Search department"
						/>
					</div>
			}
			<div>
				<Label title="Bill Status" />
				<select className='primary'
					value={queryParams.get("billStatus") || ""}
					onChange={(v) =>
						handleQueryParamChange(
							"billStatus",
							v.target.value
						)
					} >
					<option value=''>Select Bill Status</option>
					<option value='not_set'>Not Set</option>
					{BILL_STATUS_OPTTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</select>
			</div>
			{DROPDOWNS.map(
				({ title, url, param, mapFunc, labelFunc, placeholder }) => (
					<div key={param}>
						<Label title={title} />
						<PaginatedDropdown
							url={`${API_URL}/api/1.0.0${url}`}
							selectedValue={
								queryParams.get(param)
									? JSON.parse(queryParams.get(param))
									: null
							}
							onChange={(v) =>
								handleQueryParamChange(
									param,
									v ? JSON.stringify(v) : null
								)
							}
							mapOptions={(records) =>
								mapFunc(records, "id", labelFunc)
							}
							placeHolder={placeholder}
						/>
					</div>
				)
			)}
			<div>
				<Label title='Hospital Contract' />
				<select
					className='primary'
					value={queryParams.get("hospitalContract") || ""}
					onChange={(v) =>
						handleQueryParamChange(
							"hospitalContract",
							v.target.value
						)
					}>
					<option value=''>Select Hospital Contract</option>
					{HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</select>
			</div>
			<div>
				<Label title='Claim Status' />
				<select
					className='primary'
					value={queryParams.get("claimStatus") || ""}
					onChange={(v) =>
						handleQueryParamChange("claimStatus", v.target.value)
					}>
					<option value=''>Select Claim Status</option>
					{CLAIM_STATUS_DROP_DOWN_OPTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</select>
			</div>
			<div>
				<Label title='Start Date' />
				<input
					className='primary'
					type='date'
					value={queryParams.get("startDate") || ""}
					onChange={(v) =>
						handleQueryParamChange("startDate", v.target.value)
					}
				/>
			</div>
			<div>
				<Label title='End Date' />
				<input
					className='primary'
					type='date'
					value={queryParams.get("endDate") || ""}
					onChange={(v) =>
						handleQueryParamChange("endDate", v.target.value)
					}
				/>
			</div>
			<div>
				<Label title='Reset' />
				<ButtonLightTextIcon
					title='Reset'
					className='light'
					icon={
						<svg width='800px' height='800px' viewBox='0 0 512 512'>
							<path d='M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z' />
						</svg>
					}
					onClick={resetFilters}
				/>
			</div>
		</div>
	);
};

export default withRouter(TableFilter);
