import {
	getLogInUserDetails,
	getRoleInfo,
	isInDepartment,
} from "../../opl-utilities/helpers";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
	API_URL,
	BILL_STATUS_OPTTIONS,
	CLAIM_STATUS_DROP_DOWN_OPTIONS,
	getLabelByValue,
	SUGGESTED_COMMENTS,
	SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import { useUI } from "../../context/ui";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import { useScanflow } from "./scanflow-context";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { withRouter } from "react-router-dom";
import AddPatientForm from "./AddPatientForm";

const ScanflowForm = ({ history, location }) => {
	const queryParams = new URLSearchParams(location.search);
	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	//setIsTimerRunning
	const [loading, setLoading] = useState(false);
	const { task: data, refreshHandler } = useScanflow();

	const [initialScanFlowValues, setInitialScanFlowValues] = useState({
		fieldsAllowed: [],
		type: "",
		errorType: "",
		statusId: "",
		serviceDate: "",
		patient: null,
		assistant: null,
		facility: null,
		insurance: null,
		codes: "",
		patientChartNo: "",
		billStatus: "",
		claimStatus: "",
		comment: "",
	});

	const { addToastMessage } = useUI();

	const userId = getLogInUserDetails("id");
	const role = getLogInUserDetails("type");
	const isAssignedToUser = data?.assignee?.id === userId;
	const isNotCompleted = data?.statusId !== 3 && data?.statusId !== 5;

	useEffect(() => {
		setInitialScanFlowValues({
			fieldsAllowed: data?.stepsConfig?.fieldsAllowed ? JSON.parse(data.stepsConfig.fieldsAllowed) : [],
			type: "",
			statusId: "",
			errorType: "",
			serviceDate: data?.patientActivity?.event_start_date || "",
			patient: data?.patientActivity?.patient
				? {
						value: data?.patientActivity?.patient.id,
						label:
							data?.patientActivity?.patient.firstName +
							" " +
							data?.patientActivity?.patient.lastName,
				  }
				: null,
			assistant: data?.patientActivity?.event_surgical_assitant
				? {
						value: data?.patientActivity?.event_surgical_assitant
							.id,
						label:
							data?.patientActivity?.event_surgical_assitant
								.name +
							" " +
							data?.patientActivity?.event_surgical_assitant
								.lastName,
				  }
				: null,
			facility: data?.patientActivity?.event_facility
				? {
						value: data?.patientActivity?.event_facility.id,
						label: data?.patientActivity?.event_facility
							.facilityName,
				  }
				: null,
			insurance: data?.patientActivity?.insurance
				? {
						value: data?.patientActivity?.insurance.id,
						label: data?.patientActivity?.insurance.description,
				  }
				: null,
			codes: data?.patientActivity?.cpt_codes || "",
			patientChartNo: data?.patientActivity?.patient_chart_no || "",
			billStatus: data?.patientActivity?.billStatus || "",
			claimStatus: data?.patientActivity?.claimStatus || "",
			comment: data?.comment || "",
		});
	}, [data]);

	 
	const validationSchema =  Yup.object({
		fieldsAllowed: Yup.array(),
		statusId: Yup.number().required("Status ID is required"),
		comment: Yup.string().test(
			"required-if-5",
			"Comment is required",
			function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				if (statusId == 5) {
					return !!value;
				}
				return true;
			}
		),
		serviceDate: Yup.string().test(
			"required-if-5",
			"Service date is required",
			function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				// if (statusId == 5 && fieldsAllowed.includes("serviceDate")) {
				// 	return !!value;
				// }
				return true;
			}
		),
		patient: Yup.mixed()
			.nullable()
			.test("patient-required", "Patient is required", function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				// if (statusId == 5 && fieldsAllowed.includes("patientId")) {
				// 	return value !== null && value.value;
				// }
				return true;
			}),
		assistant: Yup.mixed()
			.nullable()
			.test(
				"assistant-required",
				"Assistant is required",
				function (value) {
					const { statusId } = this.parent;
					const { fieldsAllowed } = this.parent;
					// if (statusId == 5 && fieldsAllowed.includes("assistantId")) {
					// 	return value !== null && value.value;
					// }
					return true;
				}
			),
		facility: Yup.mixed()
			.nullable()
			.test(
				"facility-required",
				"Facility is required",
				function (value) {
					const { statusId } = this.parent;
					const { fieldsAllowed } = this.parent;
					// if (statusId == 5 && fieldsAllowed.includes("facilityId")) {
					// 	return value !== null && value.value;
					// }
					return true;
				}
			),
		insurance: Yup.mixed()
			.nullable()
			.test(
				"insurance-required",
				"Insurance is required",
				function (value) {
					const { statusId } = this.parent;
					const { fieldsAllowed } = this.parent;
					// if (statusId == 5 && fieldsAllowed.includes("insuranceId")) {
					// 	return value !== null && value.value;
					// }
					return true;
				}
			),
		patientChartNo: Yup.string().test(
			"required-if-5",
			"Patient chart no. is required",
			function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				// if (statusId == 5 && fieldsAllowed.includes("patientChartNo")) {
				// 	return !!value;
				// }
				return true;
			}
		), 
		codes: Yup.string().test(
			"required-if-5",
			"Codes is required",
			function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				// if (statusId == 5 && fieldsAllowed.includes("codes")) {
				// 	return !!value;
				// }
				return true;
			}
		),
		billStatus: Yup.string().test(
			"required-if-5",
			"Bill status is required",
			function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				// if (statusId == 5 && fieldsAllowed.includes("billStatus")) {
				// 	return !!value;
				// }
				return true;
			}
		),
		claimStatus: Yup.string().test(
			"required-if-5",
			"Claim status is required",
			function (value) {
				const { statusId } = this.parent;
				const { fieldsAllowed } = this.parent;
				// if (statusId == 5 && fieldsAllowed.includes("claimStatus")) {
				// 	return !!value;
				// }
				return true;
			}
		),
	});

	// const validationRegistrationSchema = Yup.object({
	// 	statusId: Yup.number().required("Status ID is required"),
	// 	comment: Yup.string().test(
	// 		"required-if-5",
	// 		"Comment is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// 	serviceDate: Yup.string().test(
	// 		"required-if-5",
	// 		"Service date is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// 	patient: Yup.mixed()
	// 		.nullable()
	// 		.test("patient-required", "Patient is required", function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return value !== null && value.value;
	// 			}
	// 			return true;
	// 		}),
	// 	assistant: Yup.mixed()
	// 		.nullable()
	// 		.test(
	// 			"assistant-required",
	// 			"Assistant is required",
	// 			function (value) {
	// 				const { statusId } = this.parent;
	// 				if (statusId == 5) {
	// 					return value !== null && value.value;
	// 				}
	// 				return true;
	// 			}
	// 		),
	// 	facility: Yup.mixed()
	// 		.nullable()
	// 		.test(
	// 			"facility-required",
	// 			"Facility is required",
	// 			function (value) {
	// 				const { statusId } = this.parent;
	// 				if (statusId == 5) {
	// 					return value !== null && value.value;
	// 				}
	// 				return true;
	// 			}
	// 		),
	// 	insurance: Yup.mixed()
	// 		.nullable()
	// 		.test(
	// 			"insurance-required",
	// 			"Insurance is required",
	// 			function (value) {
	// 				const { statusId } = this.parent;
	// 				if (statusId == 5) {
	// 					return value !== null && value.value;
	// 				}
	// 				return true;
	// 			}
	// 		),
	// 	patientChartNo: Yup.string().test(
	// 		"required-if-5",
	// 		"Patient chart no. is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// });

	// const validationCodingSchema = Yup.object({
	// 	comment: Yup.string().test(
	// 		"required-if-5",
	// 		"Comment is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// 	statusId: Yup.number().required("Status ID is required"),
	// 	codes: Yup.string().test(
	// 		"required-if-5",
	// 		"Codes is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// });

	// const validationSortingSchema = Yup.object({
	// 	comment: Yup.string().test(
	// 		"required-if-5",
	// 		"Comment is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// 	statusId: Yup.number().required("Status ID is required"),
	// 	billStatus: Yup.string().test(
	// 		"required-if-5",
	// 		"Bill status is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// });

	// const validationBillingSchema = Yup.object({
	// 	comment: Yup.string().test(
	// 		"required-if-5",
	// 		"Comment is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// 	claimStatus: Yup.string().test(
	// 		"required-if-5",
	// 		"Claim status is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// });

	// const validationCommonSchema = Yup.object({
	// 	comment: Yup.string().test(
	// 		"required-if-5",
	// 		"Comment is required",
	// 		function (value) {
	// 			const { statusId } = this.parent;
	// 			if (statusId == 5) {
	// 				return !!value;
	// 			}
	// 			return true;
	// 		}
	// 	),
	// });

	const currentStepId = data?.stepsConfig?.id;

	// const isFieldMappedToStep = (fieldStep, currentStep) => {
	// 	const passFields = {
	// 		1: ["billStatus", "comment"], // for 'sorting' step
	// 		2: [
	// 			"serviceDate",
	// 			"patientId",
	// 			"assistantId",
	// 			"facilityId",
	// 			"insuranceId",
	// 			"patientChartNo",
	// 			"comment"
	// 		], // for 'data-entry' step
	// 		3: ["codes", "comment"], // for 'administrative-coding' step
	// 		4: ["comment"],
	// 		5: ["claimStatus", "comment"],
	// 	};

	// 	if (fieldStep && currentStep) {
	// 		if (passFields[currentStep].includes(fieldStep)) {
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}
	// 	} else {
	// 		return false;
	// 	}
	// };

	const isFieldAllowed = (fieldStep, fieldsAllowed) => {
		if(fieldsAllowed && fieldsAllowed.length){
			return Boolean(fieldStep && fieldsAllowed && fieldsAllowed.includes(fieldStep));
		}else{
			return false;
		}
	};

	const handleScanFlowSubmit = async (values) => {
		setLoading(true);
		// setIsTimerRunning( 2 === parseInt(values.statusId) ? true : false);
		try {
			let body = {
				userId: userId,
				stepExecutionId: data.id,
				statusId: values.statusId,
				comment: values.comment,
				data: {
					billStatus: values?.billStatus || "",
					serviceDate: values?.serviceDate || null,
					patientId: values?.patient?.value || null,
					assistantId: values?.assistant?.value || null,
					facilityId: values?.facility?.value || null,
					insuranceId: values?.insurance?.value || null,
					patientChartNo: values?.patientChartNo || "",
					serviceDate: values?.serviceDate || null,
					patientId: values?.patient?.value || null,
					assistantId: values?.assistant?.value || null,
					facilityId: values?.facility?.value || null,
					insuranceId: values?.insurance?.value || null,
					patientChartNo: values?.patientChartNo || "",
					codes: values.codes || "",
					claimStatus: values.claimStatus || "",
				},
			};
			// if (isInDepartment(role, "data-entry")) {
			// 	body["data"] = {
			// 		serviceDate: values?.serviceDate || null,
			// 		patientId: values?.patient?.value || null,
			// 		assistantId: values?.assistant?.value || null,
			// 		facilityId: values?.facility?.value || null,
			// 		insuranceId: values?.insurance?.value || null,
			// 		patientChartNo: values?.patientChartNo || "",
			// 	};
			// }
			// if (isInDepartment(role, "data-entry")) {
			// 	body["data"] = {
			// 		billStatus: values?.billStatus || "",
			// 	};
			// }
			// if (isInDepartment(role, "billing")) {
			// 	body["data"] = {
			// 		codes: values.codes || "",
			// 	};
			// }
			const resp = await fetch(
				API_URL + `/api/1.0.0/scanflow-v2/change-status`,
				{
					method: "POST",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": SYSTEM_TOKEN,
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					addToastMessage({
						status: true,
						message: `Task ${values.type} successfully.`,
					});
					let tab = "not-started";
					switch (parseInt(values.statusId)) {
						case 2:
							tab = "in-progress";
							break;
						case 4:
							tab = "paused";
							break;
						case 5:
							tab = "not-started";
							break;
						default:
							tab = "not-started";
					} 
					updateQueryParam("tab",tab);
					refreshHandler();
				} else {
					// setIsTimerRunning( 2 !== parseInt(values.statusId) ? true : false);
					addToastMessage({
						status: false,
						message: `Something went wrong while ${values.errorType} task. Please try again.`,
					})
				}
			} else {
				// setIsTimerRunning( 2 !== parseInt(values.statusId) ? true : false);
				addToastMessage({
					status: false,
					message: `Something went wrong while ${values.errorType} task. Please try again.`,
				});
			}
		} catch (e) {
			console.log("error", e);
			// setIsTimerRunning( 2 !== parseInt(values.statusId) ? true : false);
			addToastMessage({
				status: false,
				message: `Something went wrong while ${values.errorType} task. Please try again.`,
			});
		} finally {
			setLoading(false);
		}
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='color-primary f-w-300 l-h-1'
				style={{ fontSize: "13px", paddingBottom: "8px" }}>
				{title}
			</p>
		);
	};

	const LikeInput = ({ title, ...props }) => {
		return (
			<div
				className='ui-like-input col-1-1 cursor-not-allowed'
				{...props}>
				{title ? title : " "}
			</div>
		);
	};

	const FieldWrapper = ({ title, children }) => (
		<div style={{ width: "100%", paddingBottom: "10px" }}>
			<FieldTitle title={title} />
			{children}
		</div>
	);

	const BillStatusField = ({
		userId,
		isAssignedToUser,
		isNotCompleted,
		role,
		data,
		errors,
		touched,
		values,
	}) =>
		userId &&
		isAssignedToUser &&
		isNotCompleted &&
		// isInDepartment(role, "data-entry") &&
		// isFieldMappedToStep("billStatus", currentStepId) &&
		isFieldAllowed("billStatus", values.fieldsAllowed) &&
		![4, 1].includes(data.statusId) ? (
			<>
				<Field
					as='select'
					name='billStatus'
					className={`primary ${
						errors.billStatus && touched.billStatus ? "error" : ""
					}`}>
					<option value=''>Select Bill Status</option>
					{BILL_STATUS_OPTTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</Field>
				<ErrorMessage
					name='billStatus'
					component='p'
					className='error-messages'
				/>
			</>
		) : (
			<LikeInput
				title={
					values.billStatus
						? getLabelByValue(values.billStatus) || "-"
						: "-"
				}
			/>
		);

	const labelPatientsFunc = (o) => `${o.firstName} ${o.lastName}`;
	const labelFacilityFunc = (o) => `${o.facilityName}`;
	const labelInsurancesFunc = (o) => `${o.description}`;
	const labelAssistantsFunc = (o) => `${o.fullName}`;

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	// const validateBasedOnStep = (stepId) => {
	// 	if (stepId) {
	// 		switch (stepId) {
	// 			case 1:
	// 				/* For Sorting Step */
	// 				return validationSortingSchema;
	// 			case 2:
	// 				/* For Data - Entry Step */
	// 				return validationRegistrationSchema;
	// 			case 3:
	// 				/* For Administrative Coding Step */
	// 				return validationCodingSchema;
	// 			case 5:
	// 				/* For Billing Final Step */
	// 				return validationBillingSchema;
	// 			default:
	// 				return validationCommonSchema;
	// 		}
	// 	} else {
	// 		return validationCommonSchema;
	// 	}
	// };

	function getBillStatusLabelByValue(value) {
		const option = CLAIM_STATUS_DROP_DOWN_OPTIONS.find(
			(opt) => opt.value === value
		);
		return option ? option.label : null;
	}

	const ClaimStatusField = ({
		userId,
		isAssignedToUser,
		isNotCompleted,
		role,
		data,
		errors,
		touched,
		values,
	}) =>
		userId &&
		isAssignedToUser &&
		isNotCompleted &&
		// isInDepartment(role, "data-entry") &&
		// isFieldMappedToStep("claimStatus", currentStepId) &&
		isFieldAllowed("claimStatus", values.fieldsAllowed) &&
		![4, 1].includes(data.statusId) ? (
			<>
				<Field
					as='select'
					name='claimStatus'
					className={`primary ${
						errors.claimStatus && touched.claimStatus ? "error" : ""
					}`}>
					<option value=''>Select Claim Status</option>
					{CLAIM_STATUS_DROP_DOWN_OPTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</Field>
				<ErrorMessage
					name='claimStatus'
					component='p'
					className='error-messages'
				/>
			</>
		) : (
			<LikeInput
				title={
					values.claimStatus
						? getBillStatusLabelByValue(values.claimStatus) || "-"
						: "-"
				}
			/>
		);

	return (
		<Formik
			initialValues={initialScanFlowValues}
			// validationSchema={
			// 	isInDepartment(role, "data-entry")
			// 		? validationRegistrationSchema
			// 		: isInDepartment(role, "billing")
			// 		? validationCodingSchema
			// 		: isInDepartment(role, "data-entry")
			// 		? validationSortingSchema
			// 		: validationCommonSchema
			// }
			// validationSchema={validateBasedOnStep(currentStepId)}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={handleScanFlowSubmit}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
			}) => (
				<Form>
					<div>
						{userId && isAssignedToUser && isNotCompleted && (
							<>
								<div className='col-1-1'>
									<ButtonTextIcon
										icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.536 7.555c-1.188-.252-4.606-.904-5.536-1.088v-3.512c0-1.629-1.346-2.955-3-2.955s-3 1.326-3 2.955v7.457c-.554-.336-1.188-.621-1.838-.715-1.822-.262-3.162.94-3.162 2.498 0 .805.363 1.613 1.022 2.271 3.972 3.972 5.688 5.125 6.059 9.534h9.919v-1.748c0-5.154 3-6.031 3-10.029 0-2.448-1.061-4.157-3.464-4.668zm.357 8.022c-.821 1.483-1.838 3.319-1.891 6.423h-6.13c-.726-3.82-3.81-6.318-6.436-8.949-.688-.686-.393-1.37.442-1.373 1.263-.006 3.06 1.884 4.122 3.205v-11.928c0-.517.458-.955 1-.955s1 .438 1 .955v6.948c0 .315.256.571.572.571.314 0 .57-.256.57-.571v-.575c0-.534.49-.938 1.014-.833.398.079.686.428.686.833v1.273c0 .315.256.571.571.571s.571-.256.571-.571v-.83c0-.531.487-.932 1.008-.828.396.078.682.424.682.828v1.533c0 .315.256.571.571.571s.571-.256.571-.571v-.912c0-.523.545-.867 1.018-.646.645.305 1.166.932 1.166 2.477 0 1.355-.465 2.193-1.107 3.354z" /></svg>}
										disabled={loading}
										title={
											data?.statusId == 1
												? "Start "
												: data?.statusId == 2
													? " Pause "
													: " Resume"
										}
										style={{ width: "100%" }}
										type='button'
										onClick={async () => {
											if (data?.statusId == 1) {
												await setFieldValue(
													"statusId",
													2
												);
												await setFieldValue(
													"type",
													"started"
												);
												await setFieldValue(
													"errorType",
													"starting"
												);
											} else if (data?.statusId == 2) {
												await setFieldValue(
													"statusId",
													4
												);
												await setFieldValue(
													"type",
													"paused"
												);
												await setFieldValue(
													"errorType",
													"pausing"
												);
											} else if (data?.statusId == 4) {
												await setFieldValue(
													"statusId",
													2
												);
												await setFieldValue(
													"type",
													"resumed"
												);
												await setFieldValue(
													"errorType",
													"resuming"
												);
											} else {
												console.log("Error :",
													"statusId",
													data?.statusId
												);
											}
											const errors = await validateForm();
											handleSubmit();
										}}
									/>
								</div>
								<br />
							</>)}

						{/* Bill Status Field */}
						<FieldWrapper
							title={
								<span>
									Bill Status
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep( "billStatus", currentStepId ) &&
		                            isFieldAllowed("billStatus", values.fieldsAllowed) &&		
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							<BillStatusField
								userId={userId}
								isAssignedToUser={isAssignedToUser}
								isNotCompleted={isNotCompleted}
								role={role}
								data={data}
								errors={errors}
								touched={touched}
								values={values}
							/>
						</FieldWrapper>

						{/* Service Date Field */}
						<FieldWrapper
							title={
								<span>
									Service Date
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep( "serviceDate", currentStepId ) &&
		                            isFieldAllowed("serviceDate", values.fieldsAllowed) &&	
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "data-entry") &&
							// isFieldMappedToStep("serviceDate", currentStepId) &&
							isFieldAllowed("serviceDate", values.fieldsAllowed) &&	
							![4, 1].includes(data.statusId) ? (
								<>
									<Field
										name='serviceDate'
										type='date'
										placeholder='Enter Service Date *'
										disabled={
											// !isInDepartment(
											// 	role,
											// 	"registration"
											// )
											// !isFieldMappedToStep( "serviceDate", currentStepId )
							                !isFieldAllowed("serviceDate", values.fieldsAllowed)
										}
										className={`primary col-1-1 ${
											errors.serviceDate &&
											touched.serviceDate
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='serviceDate'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput title={values.serviceDate || "-"} />
							)}
						</FieldWrapper>

						{/* Patient Field */}
						<FieldWrapper
							title={
								<span>
									Patient
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep(
									// 	"patientId",
									// 	currentStepId
									// ) &&
									isFieldAllowed("patientId", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "data-entry") &&
							// isFieldMappedToStep("patientId", currentStepId) &&
							isFieldAllowed("patientId", values.fieldsAllowed) &&
							![4, 1].includes(data.statusId) ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/patients`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelPatientsFunc
											)
										}
										error={errors.patient && touched.patient}
										placeHolder=''
										selectedValue={values.patient}
										onChange={(v) =>
											setFieldValue("patient", v)
										}
									/>
									<ErrorMessage
										name='patient'
										component='p'
										className='error-messages'
									/>
									{/* <AddPatientForm callback={(v)=>{ setFieldValue("patient", v) }}/> */}
								</>
							) : (
								<LikeInput
									title={values?.patient?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Assistant Field */}
						<FieldWrapper
							title={
								<span>
									Assistant
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep(
									// 	"assistantId",
									// 	currentStepId
									// ) &&
									isFieldAllowed("assistantId", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "data-entry") &&
							// isFieldMappedToStep("assistantId", currentStepId) &&
							isFieldAllowed("assistantId", values.fieldsAllowed) &&
							![4, 1].includes(data.statusId) ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/assistants`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelAssistantsFunc
											)
										}
										error={errors.assistant && touched.assistant}
										placeHolder=''
										selectedValue={values.assistant}
										onChange={(v) =>
											setFieldValue("assistant", v)
										}
									/>
									<ErrorMessage
										name='assistant'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.assistant?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Facility Field */}
						<FieldWrapper
							title={
								<span>
									Facility
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep(
									// 	"facilityId",
									// 	currentStepId
									// ) &&
									isFieldAllowed("facilityId", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "data-entry") &&
							// isFieldMappedToStep("facilityId", currentStepId) &&
							isFieldAllowed("facilityId", values.fieldsAllowed) &&
							![4, 1].includes(data.statusId) ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/facility`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelFacilityFunc
											)
										}
										error={errors.facility && touched.facility}
										placeHolder=''
										selectedValue={values.facility}
										onChange={(v) =>
											setFieldValue("facility", v)
										}
									/>
									<ErrorMessage
										name='facility'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.facility?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Insurance Field */}
						<FieldWrapper
							title={
								<span>
									Insurance
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep(
									// 	"insuranceId",
									// 	currentStepId
									// ) &&
									isFieldAllowed("insuranceId", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "data-entry") &&
							// isFieldMappedToStep("insuranceId", currentStepId) &&
							isFieldAllowed("insuranceId", values.fieldsAllowed) &&
							![4, 1].includes(data.statusId) ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/insurances`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelInsurancesFunc
											)
										}
										error={errors.insurance && touched.insurance}
										placeHolder=''
										selectedValue={values.insurance}
										onChange={(v) =>
											setFieldValue("insurance", v)
										}
									/>
									<ErrorMessage
										name='insurance'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.insurance?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Patient Chart No. Field */}
						<FieldWrapper
							title={
								<span>
									Patient Chart No.
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep(
									// 	"patientChartNo",
									// 	currentStepId
									// ) &&
									isFieldAllowed("patientChartNo", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "data-entry") &&
							// isFieldMappedToStep(
							// 	"patientChartNo",
							// 	currentStepId
							// ) &&
							isFieldAllowed("patientChartNo", values.fieldsAllowed) &&
							![4, 1].includes(data.statusId) ? (
								<>
									<Field
										name='patientChartNo'
										type='text'
										placeholder='12345 *'
										disabled={
											// !isInDepartment(role, "data-entry")
											// !isFieldMappedToStep(
											// 	"patientChartNo",
											// 	currentStepId
											// )
											!isFieldAllowed("patientChartNo", values.fieldsAllowed)
										}
										className={`primary  ${
											errors.patientChartNo &&
											touched.patientChartNo
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='patientChartNo'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values.patientChartNo || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Codes Field */}
						<FieldWrapper
							title={
								<span>
									Codes
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "billing") &&
									// isFieldMappedToStep(
									// 	"codes",
									// 	currentStepId
									// ) &&
									isFieldAllowed("codes", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							// isInDepartment(role, "billing") &&
							// isFieldMappedToStep("codes", currentStepId) &&
							isFieldAllowed("codes", values.fieldsAllowed) &&
							![4, 1].includes(data.statusId) ? (
								<>
									<Field
										as='textarea'
										name='codes'
										placeholder='12345,4567,... *'
										disabled={
											// !isInDepartment(role, "billing") ||
											// !isFieldMappedToStep(
											// 	"codes",
											// 	currentStepId
											// ) || 
									       !isFieldAllowed("codes", values.fieldsAllowed) || data?.assignee?.id !== userId 
										}
										className={`primary ${
											errors.codes && touched.codes
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='codes'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values.codes || "-"}
									style={{ height: "100px" }}
								/>
							)}
						</FieldWrapper>

						{/* Claim Status */}
						<FieldWrapper
							title={
								<span>
									Claim Status
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									// isInDepartment(role, "data-entry") &&
									// isFieldMappedToStep(
									// 	"claimStatus",
									// 	currentStepId
									// ) &&
									isFieldAllowed("claimStatus", values.fieldsAllowed) &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							<ClaimStatusField
								userId={userId}
								isAssignedToUser={isAssignedToUser}
								isNotCompleted={isNotCompleted}
								role={role}
								data={data}
								errors={errors}
								touched={touched}
								values={values}
							/>
						</FieldWrapper>

						{/* Comment Field */}
						<FieldWrapper
							title={
								<span>
									Comment
									{userId &&
									isAssignedToUser &&
									isNotCompleted &&
									![4, 1].includes(data.statusId) ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{userId &&
							isAssignedToUser &&
							isNotCompleted &&
							![4, 1].includes(data.statusId) ? (
								<>
									<Field
										as='textarea'
										name='comment'
										placeholder='Enter your comment'
										className={`primary ${
											errors.comment && touched.comment
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='comment'
										component='p'
										className='error-messages'
									/>
									<div
										className='flex center-left'
										style={{
											gap: "6px",
											padding: "10px 0",
											flexWrap: "wrap",
										}}>
										{SUGGESTED_COMMENTS?.map((c, i) => (
											<div
												key={i}
												class='flex center-center b-r-20' 
												style={{
													background:
														"rgb(240, 240, 240)",
													padding: "10px 12px",
													width: "48%",
													marginBottom: "8px",
												}}
												onClick={() => {
													setFieldValue("comment", c);
												}}>
												<p
													class='l-h-1 f-w-300 color-primary'
													style={{
														width: "100px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow:
															"ellipsis",
													}}>
													{c}
												</p>
											</div>
										))}
									</div>
								</>
							) : (
								<LikeInput
									title={values.comment || "-"}
									style={{ height: "100px" }}
								/>
							)}
						</FieldWrapper>

						{/*  */}
						{/*  */}
						{/*  */}
						{/*  */}

						{/* Submission Loading */}
						<div
							className='flex center-left '
							style={{ gap: "5px", paddingBlock: "12px" }}>
							<p>&nbsp;</p>
							{loading && (
								<>
									<LoaderIcon
										style={{
											width: "10px",
											height: "10px",
										}}
									/>
									<p
										className='color-primary f-w-300'
										style={{ fontSize: "12px" }}>
										Saving your changes, please wait...
									</p>
								</>
							)}
						</div>

						{/* Submission Buttons */}
						{userId && isAssignedToUser && isNotCompleted && (
							<div > 
								<div className='col-1-1'>
									<ButtonTextIcon
										title='Update & End'
										style={{ width: "100%" }}
										type='button'
										onClick={async () => {
											await setFieldValue("statusId", 5);
											await setFieldValue(
												"type",
												"completed"
											);
											await setFieldValue(
												"errorType",
												"completing"
											);
											const errors = await validateForm();
											handleSubmit();
										}}
										disabled={
											![2].includes(data?.statusId) ||
											loading
										}
									/>
								</div>
							</div>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default withRouter(ScanflowForm);