import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import Header from "../components/common/ui/Header";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import UpdateActivityForm from "./activities/UpdateActivityForm";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { UIProvider } from "../context/ui";

const UpdateActivity = ({ history, location, activePage }) => {
	const id = location.state?.id;
	const [activityData, setActivityData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchActivityData = async (id) => {
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/activity/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": SYSTEM_TOKEN,
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setActivityData(response.data);
					setLoading(false);
				}
			}
		} catch (e) {
			setError("Failed to fetch activity data.");
			setLoading(false);
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchActivityData(id);
	}, [id]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='add-activity'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
							Update Activity
						</h5>
						<ButtonTextIcon
							title='Return Back'
							className='light mar-l-a'
							onClick={() => history.push("/activities")}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19.995'
									height='19.996'
									viewBox='0 0 19.995 19.996'>
									<path
										d='M12.017,2a10,10,0,1,1-10,10,10,10,0,0,1,10-10Zm0,1.5a8.5,8.5,0,1,0,8.5,8.5,8.5,8.5,0,0,0-8.5-8.5ZM10.489,8.21s-1.5,1.505-3.255,3.259a.75.75,0,0,0,0,1.061c1.753,1.754,3.254,3.258,3.254,3.258a.74.74,0,0,0,.526.217.751.751,0,0,0,.534-1.278L9.571,12.75h6.693a.75.75,0,0,0,0-1.5H9.571l1.978-1.979a.745.745,0,0,0-.006-1.054.754.754,0,0,0-.53-.222.738.738,0,0,0-.524.215Z'
										transform='translate(-2.019 -1.995)'
									/>
								</svg>
							}
						/>
						&nbsp;
					</div>
					<div className='col-1-1'>
						<div className='form-holder'>
							<div className='inner'>
								{!error && !loading && activityData && (
									<UpdateActivityForm
										activityData={activityData}
									/>
								)}
								{!error && loading && !activityData && (
									<p
										style={{
											height: "100vh",
											paddingBottom: "20vh",
											backgroundColor: "#fafaff",
										}}
										className='flex center-center'>
										<LoaderIcon
											style={{
												height: "32px",
												width: "32px",
											}}
										/>
									</p>
								)}
								{error && !loading && !activityData && (
									 <p
										style={{
											height: "100vh",
											paddingBottom: "20vh",
											backgroundColor: "#fafaff",
										}}
										className='flex center-center'>
										{error || "Error"}
									</p>
								)}
							</div>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};

const WrappedUpdateActivityPage = (props) => (
	<UIProvider>
		<UpdateActivity {...props} />
	</UIProvider>
);

export default withRouter(WrappedUpdateActivityPage);
