const Tabs = ({ options, active, setActive }) => {
	const getActiveTabContent = () => {
		const c = options.find((o) => o.id === active);
		return c ? c.content : "";
	};

	return (
		<>
			<ul className='tab-selector'>
				{options.map((o, i) => (
					<li
						className={`transition cursor-pointer pos-rel ${
							active === o.id ? "active-tab" : ""
						}`}
						style={{
							whiteSpace: "nowrap",
						}}
						key={i}
						onClick={() => setActive(o.id)}>
						{o.title}
					</li>
				))}
			</ul>
			<div className='tab-content'>{getActiveTabContent()}</div>
		</>
	);
};

export default Tabs;
