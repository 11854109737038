import { useState } from 'react'; 

const Accordion = ({ items }) => {
  const [activeIndices, setActiveIndices] = useState([]);

  const toggleItem = (index) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index) // Remove index if already active
        : [...prevIndices, index] // Add index if not active
    );
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <button
            className={`accordion-header light ${activeIndices.includes(index) ? 'active-accordion' : ''}`}
            onClick={() => toggleItem(index)}
          >
            {item.title}
          </button>
          <div className={`accordion-content  ${activeIndices.includes(index) ? 'open' : ''}`}> 
            <p>{item.content}</p> 
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
