import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { updateBilling } from "../../actions/scanFlow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import LoadingOverlay from "react-loading-overlay";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import DatePicker from "react-datepicker";
import { scanFlowService } from "../../services/scanFlow";
import { formatDate } from "../../utils/date";
import { createNotification } from "../../utils/notificationManager";
import loaderImage from "../../../assets/images/CoureMax.gif";
import FileDisplay from "../ecosystems/FileDisplay";
import CustomDropDown from "../organisms/CustomDropDown";
import CustomSelect from "../organisms/CustomSelect";
class ManageScanFlow extends React.Component {
  static propTypes = {
    checkedBillings: PropTypes.array.isRequired,
    updateBilling: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      faceSheetFile: null,
      faceSheetFileName: "",
      serviceDate: "",
      reportList: "",
      correspondence: "",
      patient: {},
      assistant: {},
      facility: {},
      assignSorting: {},
      billStatus: "",
      insurance: {},
      practice: {},
      patientChartNo: "",
      assignReg: {},
      regStatus: "",
      assignCoder: {},
      codingStatus: "",
      assignCharge: {},
      chargeStatus: "",
      assignClaim: {},
      claimStatus: "",
      assistantLocation: "",
      hopsContract: "",
      codes: "",
      notes: "",
    };
    this.cancel = "";
  }

  componentDidMount() {
    const checkedBillings = this.props.location.state
      ? this.props.location.state.checkedBillings
      : "";
    if (checkedBillings.length === 1) {
      this.setLoading(true);
      scanFlowService.getBillingDetail(checkedBillings[0]).then((response) => {
        const faceSheetFileArray = response.data.facesheets[0]
          ? response.data.facesheets[0].facesheet_file.split("/")
          : "";
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.FILE,
          response.data.facesheets[0].id
        );
        this.setState(
          {
            patient: {
              label: response.data.patient
                ? `${response.data.patient.first_name.toUpperCase()} ${response.data.patient.last_name.toUpperCase()}`
                : "",
              value: response.data.patient
                ? parseInt(response.data.patient.id)
                : "",
            },
            facility: {
              label: response.data.facility.facility_name.toUpperCase(),
              value: parseInt(response.data.facility.id),
            },
            assistant: {
              label: `${response.data.assistant.name.toUpperCase()} ${response.data.assistant.last_name.toUpperCase()}`,
              value: parseInt(response.data.assistant.id),
            },
            serviceDate: Date.parse(response.data.event_start_date),
            faceSheetFileName:
              faceSheetFileArray[faceSheetFileArray.length - 1],
            billStatus: response.data.bill_status,
            assignReg: {
              label: `${response.data.assign_reg
                ? response.data.assign_reg.name.toUpperCase()
                : ""
                } ${response.data.assign_reg
                  ? response.data.assign_reg.last_name.toUpperCase()
                  : ""
                }`,
              value: parseInt(
                response.data.assign_reg ? response.data.assign_reg.id : ""
              ),
            },
            regStatus: response.data.reg_status ? response.data.reg_status : "",
            assignCoder: {
              label: `${response.data.assign_coder
                ? response.data.assign_coder.name.toUpperCase()
                : ""
                } ${response.data.assign_coder
                  ? response.data.assign_coder.last_name.toUpperCase()
                  : ""
                }`,
              value: parseInt(
                response.data.assign_coder ? response.data.assign_coder.id : ""
              ),
            },
            codingStatus: response.data.coding_status
              ? response.data.coding_status
              : "",
            assignCharge: {
              label: `${response.data.assign_charge
                ? response.data.assign_charge.name.toUpperCase()
                : ""
                } ${response.data.assign_charge
                  ? response.data.assign_charge.last_name.toUpperCase()
                  : ""
                }`,
              value: parseInt(
                response.data.assign_charge
                  ? response.data.assign_charge.id
                  : ""
              ),
            },
            chargeStatus: response.data.charge_status
              ? response.data.charge_status
              : "",
            assignClaim: {
              label: `${response.data.assign_claim
                ? response.data.assign_claim.name.toUpperCase()
                : ""
                } ${response.data.assign_claim
                  ? response.data.assign_claim.last_name.toUpperCase()
                  : ""
                }`,
              value: parseInt(
                response.data.assign_claim ? response.data.assign_claim.id : ""
              ),
            },
            claimStatus: response.data.claim_status
              ? response.data.claim_status
              : "",
            hopsContract: response.data.hosp_contract
              ? response.data.hosp_contract.toUpperCase()
              : "",
            assignSorting: {
              label: `${response.data.practice_assignor
                ? response.data.practice_assignor.name.toUpperCase()
                : ""
                } ${response.data.practice_assignor
                  ? response.data.practice_assignor.last_name.toUpperCase()
                  : ""
                }`,
              value: parseInt(
                response.data.practice_assignor
                  ? response.data.practice_assignor.id
                  : ""
              ),
            },
            assistantLocation:
              response.data.assistant && response.data.assistant.region
                ? response.data.assistant.region.name
                : "",
            insurance: {
              label:
                response.data.patient &&
                  response.data.patient.patient_insurance_data &&
                  response.data.patient.patient_insurance_data.primary
                  ? response.data.patient.patient_insurance_data.primary.description.toUpperCase()
                  : "",
              value:
                response.data.patient &&
                  response.data.patient.patient_insurance_data &&
                  response.data.patient.patient_insurance_data.primary
                  ? response.data.patient.patient_insurance_data.primary.id
                  : "",
            },
            patientChartNo: response?.data?.patient?.id || "",
            practice: {
              label: response.data.practice
                ? response.data.practice.paytoname.toUpperCase()
                : "",
              value: parseInt(
                response.data.practice ? response.data.practice.id : ""
              ),
            },
            codes: response.data.cpt_codes,
            notes: response.data.notes,
          },
          () => {
            this.setLoading(false);
          }
        );
      });
    }
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  cancelNav = () => {
    this.props.history.push({
      pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
      state: {
        currentPage: this.props.location.state.currentPage,
        pageSize: this.props.location.state.pageSize,
      },
    });
  };

  update = () => {
    const { updateBilling } = this.props;
    const {
      patient,
      facility,
      assistant,
      serviceDate,
      assignSorting,
      billStatus,
      insurance,
      practice,
      assignReg,
      regStatus,
      assignCoder,
      codingStatus,
      assignCharge,
      chargeStatus,
      assignClaim,
      claimStatus,
      hopsContract,
      codes,
      notes,
    } = this.state;

    const checkedBillings = this.props.location.state
      ? this.props.location.state.checkedBillings
      : "";
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    this.setLoading(true);
    if (checkedBillings.length === 1) {
      const request = {
        event_id: checkedBillings,
        patient_id: patient.value,
        facility: facility.value,
        assistant_id: assistant.value,
        dos: formatDate(serviceDate),
        practice: practice.value,
        assign_reg_id: assignReg.value,
        reg_status: regStatus,
        assign_code_id: assignCoder.value,
        coding_status: codingStatus,
        assign_charge_id: assignCharge.value,
        charge_status: chargeStatus,
        assign_claim_id: assignClaim.value,
        claim_status: claimStatus,
        hosp_contract: hopsContract,
        assign_sorting_id: assignSorting.value,
        bill_status: billStatus,
        insurance: insurance.value,
        codes,
        notes,
        modified_by: loggedInUser.id,
      };

      updateBilling(request)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push({
              pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
              state: {
                currentPage: this.props.location.state.currentPage,
                pageSize: this.props.location.state.pageSize,
              },
            });
          }
          this.setLoading(false);
        })
        .finally((err) => {
          this.setLoading(false);
        });
    } else {
      let request = {
        event_id: checkedBillings,
      };

      if (serviceDate) {
        request = { ...request, dos: serviceDate };
      }
      if (patient.value) {
        request = { ...request, patient_id: patient.value };
      }
      if (assistant.value) {
        request = { ...request, assistant_id: assistant.value };
      }
      if (facility.value) {
        request = { ...request, facility: facility.value };
      }
      if (assignSorting.value) {
        request = { ...request, assign_sorting_id: assignSorting.value };
      }
      if (billStatus) {
        request = { ...request, bill_status: billStatus };
      }
      if (insurance.value) {
        request = { ...request, insurance: insurance.value };
      }
      if (practice.value) {
        request = { ...request, practice: practice.value };
      }
      if (assignReg.value) {
        request = { ...request, assign_reg_id: assignReg.value };
      }
      if (regStatus) {
        request = { ...request, reg_status: regStatus };
      }
      if (assignCoder.value) {
        request = { ...request, assign_code_id: assignCoder.value };
      }
      if (codingStatus) {
        request = { ...request, coding_status: codingStatus };
      }
      if (assignCharge.value) {
        request = { ...request, assign_charge_id: assignCharge.value };
      }
      if (chargeStatus) {
        request = { ...request, charge_status: chargeStatus };
      }
      if (assignClaim.value) {
        request = { ...request, assign_claim_id: assignClaim.value };
      }
      if (claimStatus) {
        request = { ...request, claim_status: claimStatus };
      }
      if (codes) {
        request = { ...request, codes: codes };
      }
      if (notes) {
        request = { ...request, notes: notes };
      }
      request = { ...request, modified_by: loggedInUser.id };

      updateBilling(request)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push({
              pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
              state: {
                currentPage: this.props.location.state.currentPage,
                pageSize: this.props.location.state.pageSize,
              },
            });
          }
          this.setLoading(false);
        })
        .finally((err) => {
          this.setLoading(false);
        });
    }
  };

  onChangePatient = (value) => {
    this.setState({ patient: value });
  };

  onChangeAssistant = (value) => {
    this.setState({ assistant: value });
  };

  onChangeFacility = (value) => {
    this.setState({ facility: value });
  };

  render() {
    const checkedBillings = this.props.location.state
      ? this.props.location.state.checkedBillings
      : "";
    let singleEdit = 0;
    let html = "";
    let Singleclass = "manage-activity-right-col";
    let Singleclassrow = "";
    let SingleClassCol = "col-md-3 mrgn-btm10";
    if (checkedBillings.length === 1) {
      singleEdit = 1;
      html = (
        <div className="col-md-8 manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              <h2 className="heading-custom">Edit</h2>
            </div>
            {/* Scan Flow File */}
            <FileDisplay></FileDisplay>
          </div>
        </div>
      );
      Singleclass = "col-md-2  manage-activity-right-col2";
      Singleclassrow = "row";
      SingleClassCol = "col-md-12 mrgn-btm10";
    }
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar />
        </div>
        <TopNavbar />
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            <img
              style={{
                width: "200px",
              }}
              src={loaderImage}
              alt="loading..."
            />
          }
        >
          <div className={Singleclassrow}>
            {singleEdit && html}
            <div
              className={Singleclass}
              style={{
                marginLeft: "0px !important",
                padding: "10px 20px 0",
              }}
            >
              <div className="activity-row">
                <div className="page-title">
                  <h2 className="heading-custom">Edit</h2>
                </div>
                <div className={SingleClassCol}>
                  <label>Service Date</label>
                  <br />
                  <DatePicker
                    isClearable
                    selected={this.state.serviceDate}
                    onChange={(date) => this.setState({ serviceDate: date })}
                    className="date-picker"
                  />
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    type={'patients'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.patient}
                    onChange={this.onChangePatient}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    type={'assistants'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.assistant}
                    onChange={this.onChangeAssistant}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    type={'facilities'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.facility}
                    onChange={this.onChangeFacility}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    label={'Assign Sorting'}
                    type={'users'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.assignSorting}
                    onChange={(value) => this.setState({ assignSorting: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomSelect type='Bill Status'
                    className="filter-input"
                    value={this.state.billStatus}
                    onChange={(event) =>
                      this.setState({ billStatus: event.target.value })
                    }>
                  </CustomSelect>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    type={'insurances'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.insurance}
                    onChange={(value) => this.setState({ insurance: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    type={'practices'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.practice}
                    onChange={(value) => this.setState({ practice: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <label>Patient Chart No</label>
                  <input
                    type="text"
                    className="code-input"
                    value={this.state.patientChartNo}
                    onChange={(event) =>
                      this.setState({ patientChartNo: event.target.value })
                    }
                  />
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    label={'Assign Reg'}
                    type={'users'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.assignReg}
                    onChange={(value) => this.setState({ assignReg: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <label>Reg Status</label>
                  <select
                    className="filter-input"
                    value={this.state.regStatus}
                    onChange={(event) =>
                      this.setState({ regStatus: event.target.value })
                    }
                  >
                    <option value="">Reg Status</option>
                    <option value="not started">NOT STARTED</option>
                    <option value="completed">COMPLETED</option>
                  </select>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    label={'Assign Coder'}
                    type={'users'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.assignCoder}
                    onChange={(value) => this.setState({ assignCoder: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomSelect type='Coding Status'
                    value={this.state.codingStatus}
                    className="filter-input"
                    onChange={(event) =>
                      this.setState({ codingStatus: event.target.value })
                    }
                  >
                  </CustomSelect>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    label={'Assign Charge'}
                    type={'users'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.assignCharge}
                    onChange={(value) => this.setState({ assignCharge: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <label>Charge Status</label>
                  <select
                    value={this.state.chargeStatus}
                    className="filter-input"
                    onChange={(event) =>
                      this.setState({ chargeStatus: event.target.value })
                    }
                  >
                    <option value="">Charge Status</option>
                    <option value="not started">NOT STARTED</option>
                    <option value="ready to submit">READY TO SUBMIT</option>
                  </select>
                </div>
                <div className={SingleClassCol}>
                  <CustomDropDown
                    label={'Assign Claim'}
                    type={'users'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state?.assignClaim}
                    onChange={(value) => this.setState({ assignClaim: value })}>
                  </CustomDropDown>
                </div>
                <div className={SingleClassCol}>
                  <CustomSelect type='Claim Status'
                    value={this.state.claimStatus}
                    className="filter-input"
                    onChange={(event) =>
                      this.setState({ claimStatus: event.target.value })
                    }
                  >
                  </CustomSelect>
                </div>
                <div className={SingleClassCol}>
                  <label>Assitant Location</label>
                  <input
                    type="text"
                    disabled
                    className="code-input"
                    value={this.state.assistantLocation}
                    onChange={(event) =>
                      this.setState({ assistantLocation: event.target.value })
                    }
                  />
                </div>
                <div className={SingleClassCol}>
                  <CustomSelect type='Hospital Contract'
                    value={this.state.hopsContract}
                    className="filter-input"
                    onChange={(event) =>
                      this.setState({ hopsContract: event.target.value })
                    }
                  >
                  </CustomSelect>
                </div>
                <div className={SingleClassCol}>
                  <label>Codes</label>
                  <input
                    type="text"
                    className="code-input"
                    value={this.state.codes}
                    onChange={(event) =>
                      this.setState({ codes: event.target.value })
                    }
                  />
                </div>
                <div className={SingleClassCol}>
                  <label>Notes</label>
                  <textarea
                    className="code-input"
                    value={this.state.notes}
                    onChange={(event) =>
                      this.setState({ notes: event.target.value })
                    }
                  />
                </div>
                <div className="filter-footer">
                  <button onClick={this.update} className="filter-save-button">
                    Update
                  </button>
                  <button
                    onClick={this.cancelNav}
                    className="filter-cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateBilling,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageScanFlow));
