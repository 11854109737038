import {
	convertGMTToLocal,
	formatDateTime,
	formatTime,
	getLogInUserDetails,
	getScanfloeStatusInfo,
	isManager,
} from "../../opl-utilities/helpers";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../../opl-utilities/constants";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import { useUI } from "../../context/ui";
import { useScanflow } from "./scanflow-context";
import ScanflowForm from "./ScanflowForm";
import CustomModal from "../../components/common/CustomModal";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import FileViewer from "./FileViewer";
import { withRouter } from "react-router-dom";

const Label = ({ title }) => (
	<p
		className='color-primary f-w-300 l-h-1'
		style={{ fontSize: "12px", paddingBottom: "6px" }}>
		{title}
	</p>
);

const AssigneeDetails = ({ data }) => {
	return (
		<div
			style={{
				padding: "20px",
				borderBottom: "solid 1px #d8d8d8",
			}}>
			<Label title='Assignee' />
			<p
				className='color-primary f-w-300 l-h-1'
				style={{ fontSize: "12px", paddingBottom: "6px" }}>
				{data?.assignee?.name || "-"} {data?.assignee?.lastName}
			</p>
		</div>
	);
};

const AssigneeFormModal = withRouter(({ history, location, data, setShow }) => {
	const queryParams = new URLSearchParams(location.search);
	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const userId = getLogInUserDetails("id");
	const role = getLogInUserDetails("type");
	const { refreshHandler, random } = useScanflow();
	const { addToastMessage } = useUI();

	const callUserApi = async () => {
		setUsers([]);
		try {
			const role = getLogInUserDetails("type");
			const resp = await fetch(
				API_URL + `/api/1.0.0/users?type=${role}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": SYSTEM_TOKEN,
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) { 
					setUsers(response.data);
				}
			}
		} catch (e) {
			console.log("error", e);
		} finally {
		}
	};

	useEffect(() => {
		callUserApi();
	}, [random]);

	const validationSchema = Yup.object({
		assignee: Yup.string().required("Assignee is required"),
	});

	return (
		<section id='opl_coremax'>
			<section
				style={{
					position: "fixed",
					inset: 0,
					zIndex: 9999,
					backgroundColor: "rgba(255, 255, 255, 0.86)",
				}}>
				<section
					className='col-1-1 flex center-center'
					style={{
						height: "100vh",
						width: "100vw",
					}}>
					<div
						style={{
							width: "400px",
							padding: "24px",
							height: "auto",
							maxHeight: "80vh",
							border: "solid 1px #f0f0f0",
							background: "white",
							borderRadius: "8px",
							margin: "0 auto",
							boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
						}}>
						<div
							className='col-1-1 flex center-left'
							style={{
								borderBottom: "solid 1px #f0f0f0",
								paddingBottom: "12px",
								marginBottom: "16px",
							}}>
							<h6
								className='color-primary f-w-300 opl-tooltip'
								data-tooltip='View entire history of the activity below.'>
								Assign Task
							</h6>
							<div
								className='mar-l-a cursor-pointer opl-tooltip'
								data-tooltip='Close to return to the previous section.'
								onClick={() => setShow(false)}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
										fill='#283f54'
									/>
								</svg>
							</div>
						</div>
						<div
						>
							<section>
								<Formik
									initialValues={{ assignee: data?.assignee?.id || "", type: "" }}
									validationSchema={validationSchema}
									enableReinitialize={true}
									onSubmit={async (values) => {
										setLoading(true);
										try {
											const resp = await fetch(
												API_URL + `/api/1.0.0/scanflow-v2/assign-task`,
												{
													method: "POST",
													body: JSON.stringify({
														userId: userId,
														role: role,
														assignee: values.assignee,
														stepExecutionId: data.id,
													}),
													headers: {
														"Content-Type": "application/json",
														"X-JWT-Assertion": SYSTEM_TOKEN,
													},
												}
											);
											if (resp.ok) {
												const response = await resp.json();
												if (response.result.responseCode === 200) {
													if (response?.data?.taskId) {
                                                        updateQueryParam("id", response?.data?.taskId);
													}
													addToastMessage({
														status: true,
														message: "Task successfully assigned.",
													});
													refreshHandler();
													setShow();
												} else {
													addToastMessage({
														status: false,
														message:
															"Something went wrong in assigning task. Please try again.",
													});
												}
											} else {
												const response = await resp.json();
												addToastMessage({
													status: false,
													message:
														response?.responseDescription ||
														"Something went wrong in assigning task. Please try again.",
												});
											}
										} catch (e) {
											console.log("error", e);
											addToastMessage({
												status: false,
												message:
													"Something went wrong in assigning task. Please try again.",
											});
										} finally {
											setLoading(false);
										}
									}}>
									{({
										handleSubmit,
										values,
										errors,
										touched,
										setFieldValue,
										validateForm,
									}) => (
										<Form onSubmit={handleSubmit}>
											<div
												style={{
													padding: "20px",
													borderBottom: "solid 1px #d8d8d8",
												}}>
												<div>
													<ButtonLightTextIcon
														title={"Assign to myself"}
														disabled={loading || userId === data?.assignee?.id}
														style={{ width: "100%" }}
														type='button'
														icon={
															loading &&
																values.type === "myself" ? (
																<LoaderIcon />
															) : (
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'>
																	<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
																</svg>
															)
														}
														onClick={async () => {
															await setFieldValue(
																"assignee",
																userId
															);
															await setFieldValue(
																"type",
																"myself"
															);
															const errors = await validateForm();
															handleSubmit();
														}}
													/>
												</div>
												<div style={{ position: 'relative', margin: "20px 0" }}>
													<div style={{
														position: 'absolute',
														inset: '0',
														display: 'flex',
														alignItems: 'center',
													}}>
														<span style={{ width: '100%', borderTop: '1px solid #ddd' }} />
													</div>
													<div style={{
														position: 'relative',
														display: 'flex',
														justifyContent: 'center',
														fontSize: '0.75rem',
														textTransform: 'uppercase',
													}}>
														<span style={{
															backgroundColor: 'white',
															padding: '0 0.5rem',
															color: '#6b7280',
														}}>
															or
														</span>
													</div>
												</div>
												<div
													style={{
														marginTop: "10px",
														marginBottom: "16px",
													}}>
													<Label title='Assign to Others' />
													<Field
														as='select'
														name={"assignee"}
														className={
															"primary " +
															(errors.assignee && touched.assignee
																? " error"
																: "")
														}
														disabled={
															!isManager(role) || data.statusId !== 1
														}>
														<option value={""}>Select User</option>
														{users?.filter((d) => ( d.id !== userId )).map((d, i) => (
															<option key={i} value={d.id}>
																{d.name} {d.last_name}
																{d.completed ||
																	d.notStarted ||
																	d.onGoing
																	? "  ("
																	: ""}
																{d.completed
																	? `Completed: ${d.completed}${d.notStarted ? "," : ""
																	} `
																	: ""}
																{d.notStarted
																	? `Not Started: ${d.notStarted}${d.onGoing ? "," : ""
																	} `
																	: ""}
																{d.onGoing
																	? `On Going: ${d.onGoing}`
																	: ""}
																{d.completed ||
																	d.notStarted ||
																	d.onGoing
																	? ")"
																	: ""}
															</option>
														))}
													</Field>
												</div>
												<div className='flex col-1-1 center-right'>
													<ButtonTextIcon
														title={data?.assignee?.name ? "Forward" : "Assign"}
														type='button'
														onClick={async () => { 
															await setFieldValue(
																"type",
																"other"
															);
															const errors = await validateForm();
															handleSubmit();
														}}
														style={{ width: "100%" }}
														disabled={loading}
														icon={
															loading &&
																values.type === "other" ? (
																<LoaderIcon />
															) : (
																data?.assignee?.name ?
																	<svg
																		clipRule="evenodd"
																		fillRule="evenodd"
																		strokeLinejoin="round"
																		strokeMiterlimit="2"
																		viewBox="0 0 24 24"
																	>
																		<path
																			d="M12.012 1.995c-5.518 0-9.998 4.48-9.998 9.998s4.48 9.998 9.998 9.998 9.997-4.48 9.997-9.998-4.479-9.998-9.997-9.998zm0 1.5c4.69 0 8.497 3.808 8.497 8.498s-3.807 8.498-8.497 8.498-8.498-3.808-8.498-8.498 3.808-8.498 8.498-8.498zm1.528 4.715s1.502 1.505 3.255 3.259c.146.147.219.339.219.531s-.073.383-.219.53c-1.753 1.754-3.254 3.258-3.254 3.258-.145.145-.336.217-.527.217-.191-.001-.383-.074-.53-.221-.293-.293-.295-.766-.004-1.057l1.978-1.977h-6.694c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6.694l-1.979-1.979c-.289-.289-.286-.762.006-1.054.147-.147.339-.221.531-.222.19 0 .38.071.524.215z"
																			fillRule="nonzero"
																		/>
																	</svg> :
																	<svg
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'>
																		<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
																	</svg>
															)
														}
													/>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</section>
						</div>
						<div
							className='col-1-1 flex center-left'
							style={{
								marginTop: "16px",
								borderTop: "solid 1px #f0f0f0",
								paddingTop: "16px",
							}}>
							<div style={{ marginLeft: "auto" }}>
								<ButtonLightTextIcon
									title='Return Back'
									onClick={() => setShow(false)}
									icon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'>
											<path
												d='M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1ZM7.172,12.5l4.608,3.763L11.1,17,5,12l6.112-5,.666.753L7.174,11.5H19v1Z'
												fillRule='evenodd'
											/>
										</svg>
									}
								/>
							</div>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
});

const Timer = ({ task }) => {
	const [elapsedTime, setElapsedTime] = useState(0);
	const [isTimerRunning, setIsTimerRunning] = useState(false);
	useEffect(() => {
		let timerInterval = null;
		if (isTimerRunning) {
			timerInterval = setInterval(() => {
				setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
			}, 1000);
		}
		return () => clearInterval(timerInterval);
	}, [isTimerRunning]);

	useEffect(() => {
		setElapsedTime(0);
		if (task?.statusId === 2) {
			const intervals = task?.intervals
				? JSON.parse(task?.intervals)
				: "";
			if (intervals && intervals.length > 0) { 
				const savedStartTime =
					intervals[intervals.length - 1].startTime;
				if (savedStartTime) {
					const defaultDurationInSeconds = task.timeSpent;
					const startTime = new Date(
						convertGMTToLocal(savedStartTime.replace(" ", "T"))
					);
					const currentTime = new Date();
					const elapsedSeconds = Math.floor(
						(currentTime - startTime) / 1000
					);
					const newRemainingTime =
						defaultDurationInSeconds + elapsedSeconds;
					setElapsedTime(newRemainingTime > 0 ? newRemainingTime : 0);
				}
			}
			setIsTimerRunning(true);
		} else {
			setElapsedTime(task.timeSpent ? task.timeSpent : 0);
			setIsTimerRunning(false);
		}
	}, [task]);

	return (
		<h4 className='l-h-1-2 f-w-900 t-a-r' style={{ color: "#7f8c8d" }}>
			{formatTime(elapsedTime)}
		</h4>
	);
};

const TaskDetails = () => {
	const { task } = useScanflow();
	const userId = getLogInUserDetails("id");
	const role = getLogInUserDetails("type");

	return (
		<>
			<div className='doc-container'>
				<div
					className='col-1-1 flex center-left'
					style={{
						padding: "16px 20px",
						borderBottom: "solid 1px #e3e3e3",
					}}>
					<div>
						<h5
							className='l-h-1-2 f-w-700'
							style={{ color: "#2c3e50" }}>
							Document
						</h5>
						<p
							className='l-h-1 f-w-300'
							style={{ color: "#8f8f8f" }}>
							(Facesheet)
						</p>
					</div>
					<div className='mar-l-a'>
						<Timer task={task} />
						<p
							className='l-h-1 f-w-300 t-a-r '
							style={{ color: "#8f8f8f" }}>
							(<span className="t-t-u">{task?.stepsConfig?.stepName || "NA"}</span> | {getScanfloeStatusInfo("id", task?.statusId).name})
						</p>
					</div>
				</div>
				<div
					style={{
						backgroundColor: "#ecf0f1",
						width: "auto",
						height: "100%",
						padding: "12px",
					}}>
					{task?.patientActivity?.facesheetURL ? (
						<FileViewer
							fileUrl={task?.patientActivity?.facesheetURL}
						/>
					) : (
						<div className='flex center-center'>
							<p>
								Sorry, the Document you're looking for could not
								be found.
							</p>
						</div>
					)}
				</div>
			</div>
			<div className='from-container'>
				<div>
					<AssigneeDetails data={task} />
					{isManager(role) && [1].includes(task.statusId) && (
						<div
							style={{
								padding: "20px",
								borderBottom: "solid 1px #d8d8d8",
							}}>
							<CustomModal
								selector={
									<ButtonTextIcon style={{ width: "100%" }} icon={
										task?.assignee?.name ?
											<svg
												clipRule="evenodd"
												fillRule="evenodd"
												strokeLinejoin="round"
												strokeMiterlimit="2"
												viewBox="0 0 24 24"
											>
												<path
													d="M12.012 1.995c-5.518 0-9.998 4.48-9.998 9.998s4.48 9.998 9.998 9.998 9.997-4.48 9.997-9.998-4.479-9.998-9.997-9.998zm0 1.5c4.69 0 8.497 3.808 8.497 8.498s-3.807 8.498-8.497 8.498-8.498-3.808-8.498-8.498 3.808-8.498 8.498-8.498zm1.528 4.715s1.502 1.505 3.255 3.259c.146.147.219.339.219.531s-.073.383-.219.53c-1.753 1.754-3.254 3.258-3.254 3.258-.145.145-.336.217-.527.217-.191-.001-.383-.074-.53-.221-.293-.293-.295-.766-.004-1.057l1.978-1.977h-6.694c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6.694l-1.979-1.979c-.289-.289-.286-.762.006-1.054.147-.147.339-.221.531-.222.19 0 .38.071.524.215z"
													fillRule="nonzero"
												/>
											</svg> :
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
											</svg>
									}
										title={task?.assignee?.name ? "Forward" : "Assign"}
									/>
								}
								content={
									<AssigneeFormModal  data={task}/>
								}
							/>
						</div>
					)
					}
				</div>
				<div style={{ padding: "12px 16px" }}>
					<div
						className='col-1-1 flex center-left'
						style={{
							paddingBottom: "12px",
							borderBottom: "solid 1px #f0f0f0",
							marginBottom: "16px",
						}}>
						<h6 className='color-primary f-w-600'>Update</h6>
						<div className='mar-l-a'>
							<CustomModal
								selector={
									<ButtonLightTextIcon
										title='Scanflow history'
										icon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-8v-9h2v7h6v2z' />
											</svg>
										}
									/>
								}
								content={
									<ScanFlowHistoryContent
										id={task?.patientActivity?.id}
									/>
								}
							/>
						</div>
					</div>
					<ScanflowForm />
					{/* setIsTimerRunning={ () => { setIsTimerRunning(); }}  */}
				</div>
			</div>
		</>
	);
};

export default TaskDetails;

const DetailRow = ({ label, value }) => (
	<div>
		<p
			className='f-w-300 l-h-1'
			style={{
				fontSize: "12px",
				paddingBottom: "4px",
				color: "#aaaaaa",
			}}>
			{label}:
		</p>
		<p className='f-w-400 color-primary l-h-1' style={{}}>
			{value}
		</p>
	</div>
);

const ScanFlowHistoryContent = ({ id, setShow }) => {
	const [history, setHistory] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { random } = useScanflow();

	const callApi = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				`${API_URL}/api/1.0.0/scanflow-v2/activity-history?patientActivityId=${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": SYSTEM_TOKEN,
					},
				}
			);
			if (!resp.ok) throw new Error("Failed to fetch history");
			const { data } = await resp.json();
			setHistory(data);
		} catch (e) {
			setError("An error occurred while retrieving scanflow history.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) callApi();
	}, [id, random]);

	const getRandomColor = () => {
		const array = ["#F08D3B", "#E65464", "#56BAEC", "#8e44ad", "#27ae60"];
		const randomIndex = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	};

	if (!id) return null;

	return (
		<section id='opl_coremax'>
			<section
				style={{
					position: "fixed",
					inset: 0,
					zIndex: 9999,
					backgroundColor: "rgba(255, 255, 255, 0.86)",
				}}>
				<section
					className='col-1-1 flex center-center'
					style={{
						height: "100vh",
						width: "100vw",
					}}>
					<div
						style={{
							width: "486px",
							padding: "24px",
							height: "auto",
							maxHeight: "80vh",
							border: "solid 1px #f0f0f0",
							background: "white",
							borderRadius: "8px",
							margin: "0 auto",
							boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
						}}>
						<div
							className='col-1-1 flex center-left'
							style={{
								borderBottom: "solid 1px #f0f0f0",
								paddingBottom: "12px",
								marginBottom: "16px",
							}}>
							<h6
								className='color-primary f-w-300 opl-tooltip'
								data-tooltip='View entire history of the activity below.'>
								Scanflow History
							</h6>
							<div
								className='mar-l-a cursor-pointer opl-tooltip'
								data-tooltip='Close to return to the previous section.'
								onClick={() => setShow(false)}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
										fill='#283f54'
									/>
								</svg>
							</div>
						</div>
						<div
							style={{
								height: "492px",
								overflowY: "auto",
								background: "white",
							}}>
							<section>
								{loading && (
									<div
										className='col-1-1'
										style={{
											height: "40px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<LoaderIcon
											style={{
												width: "20px",
												height: "20px",
											}}
										/>
									</div>
								)}
								{!loading && error && (
									<p
										className='f-w-300 color-primary'
										style={{ color: "#c0392b" }}>
										{error}
									</p>
								)}
								{!loading &&
									!error &&
									history &&
									history?.length &&
									history.map(
										(
											{
												stepsConfig,
												assignee,
												assignedAt,
												createdAt,
												updatedAt,
												statusId,
												timeSpent,
												comment,
											},
											i
										) => (
											<div className='col-1-1' key={i}>
												<div>
													<div className='flex center-left'>
														<div
															className='flex center-center b-r-100'
															style={{
																flex: "0 40px",
																background:
																	"#f0f0f0",
																height: "40px",
																width: "40px",
															}}>
															<p
																className='f-w-300 color-primary l-h-1'
																style={{
																	fontSize:
																		"11px",
																}}>
																#
																<span className='f-w-700'>
																	{history.length -
																		i}
																</span>
															</p>
														</div>
														<div
															style={{
																height: "4px",
																width: "24px",
																borderTop:
																	"dashed 2px #f0f0f0",
															}}></div>
														<div
															className='b-r-20'
															style={{
																padding:
																	"12px 16px",
																backgroundColor:
																	getRandomColor(),
															}}>
															<p
																className='color-white f-w-400 l-h-1 t-t-u'
																style={{
																	fontSize:
																		"11px",
																}}>
																{stepsConfig?.stepName ||
																	"NA"}
															</p>
														</div>
													</div>
													<div
														style={{
															height: "16px",
															width: "4px",
															borderLeft:
																"dashed 2px #f0f0f0",
															marginLeft: "20px",
														}}></div>
													<div
														className='b-r-5'
														style={{
															display: "grid",
															gridTemplateColumns:
																"repeat(2, 1fr)",
															gridTemplateRows:
																"repeat(auto-fill, auto)",
															gridColmnGap:
																"12px",
															gridRowGap: "12px",
															background:
																"#f4fbff",
															padding: "16px",
															border: "solid 1px #f0f0f0",
														}}>
														<div>
															<DetailRow
																label='Assignee'
																value={
																	assignee?.name ||
																	"NA"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Assigned At'
																value={
																	assignedAt
																		? formatDateTime(
																			assignedAt
																		).toLocaleString()
																		: "-"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Started At'
																value={
																	createdAt
																		? formatDateTime(
																			createdAt
																		).toLocaleString()
																		: "-"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Completed At'
																value={
																	updatedAt &&
																		statusId ===
																		5
																		? formatDateTime(
																			updatedAt
																		).toLocaleString()
																		: "-"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Total Time Spent'
																value={`${formatTime(
																	timeSpent ||
																	0
																)} hrs.`}
															/>
														</div>
														<div>
															<DetailRow
																label='Note'
																value={
																	comment ||
																	"-"
																}
															/>
														</div>
													</div>
													{history.length !==
														i - 1 && (
															<div
																style={{
																	height: "48px",
																	width: "4px",
																	borderLeft:
																		"dashed 2px #f0f0f0",
																	marginLeft:
																		"20px",
																}}></div>
														)}
												</div>
											</div>
										)
									)}
								{!loading &&
									!error &&
									history &&
									history.length === 0 && (
										<p className='f-w-300 color-primary'>
											No history available.
										</p>
									)}
							</section>
						</div>
						<div
							className='col-1-1 flex center-left'
							style={{
								marginTop: "16px",
								borderTop: "solid 1px #f0f0f0",
								paddingTop: "16px",
							}}>
							<ButtonLightTextIcon
								title='Return Back'
								onClick={() => setShow(false)}
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'>
										<path
											d='M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1ZM7.172,12.5l4.608,3.763L11.1,17,5,12l6.112-5,.666.753L7.174,11.5H19v1Z'
											fillRule='evenodd'
										/>
									</svg>
								}
							/>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};
