export const APP_VERSION = "2.0.1";
export const SYSTEM_TOKEN =
	"eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjEsInVzZXJOYW1lIjoiU3lzdGVtLVRva2VuIiwiYXV0aG9yaXRpZXMiOlsxXSwiaXNzIjoib2N0b3BpLWxhYnMuY29tIiwiZXhwIjoxODc5MzQwMTg1LCJpYXQiOjE3MjE2NjAxODV9.RR58MjQo9ECCW1aoMeJbtE4cvtENMfDegrt5MMzo0r0"; //process.env.OPL_SYSTEM_TOKEN;
export const API_URL = process.env.REACT_APP_OPL_API_URL; // 'http://192.168.1.189:8080';//'http://localhost:8080';//'http://192.168.1.190:8080';//'http://localhost:8080';// process.env.REACT_APP_OPL_API_URL;
export const LARAVEL_API_URL = process.env.REACT_APP_API_URL;

export const SCANFLOW_STATUS_LIST = [
	{
		id: 1,
		name: "Not Started",
		slug: "not-started",
	},
	{
		id: 2,
		name: "In Progress",
		slug: "in-progress",
	},
	{
		id: 3,
		name: "Forwarded",
		slug: "forwarded",
	},
	{
		id: 4,
		name: "Paused",
		slug: "paused",
	},
	{
		id: 5,
		name: "Completed",
		slug: "completed",
	},
];

export const BILL_STATUS_OPTTIONS = [
	{ value: "billable", label: "Billable" },
	{ value: "non billable", label: "Non Billable" },
	{ value: "hospital contract", label: "Hospital Contract" },
	{
		value: "hospital contract second scrub",
		label: "Hospital Contract Second Scrub",
	},
	{ value: "bill to patient", label: "Bill to Patient" },
	{ value: "bill to physician", label: "Bill to Physician" },
];

export const CLAIM_STATUS_DROP_DOWN_OPTIONS = [
	{ label: "NOT STARTED", value: "not started" },
	{ label: "SUBMITTED", value: "submitted" },
	{ label: "CORRECTED CLAIM", value: "corrected claim" },
	{ label: "PAPER CLAIM", value: "paper claim" },
	{ label: "RECONSIDERATION", value: "reconsideration" },
	{ label: "FOLLOW-UP", value: "followup" },
	{ label: "APPEAL 1", value: "appeal 1" },
	{ label: "APPEAL 2", value: "appeal 2" },
	{ label: "HOLD TO BILL", value: "hold to bill" },
	{ label: "BILL PATIENT", value: "bill patient" },
	{ label: "BILL HOSPITAL", value: "bill hospital" },
	{ label: "BILL PHYSICIAN", value: "bill physician" },
	{ label: "REVIEW ADJUSTMENT", value: "review adjustment" },
	{ label: "CLOSED", value: "closed" },
	{ label: "CLOSED NON-BILL", value: "closed non-bill" },
	{ label: "CLOSED ADJUSTMENT", value: "closed adjustment" },
	{ label: "CLOSED HC-2nd Scrub", value: "closed hc-2nd scrub" },
];

export const HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS = [
	{ label: "Non Contracted", value: "non-contracted" },
	{ label: "Billed Hospital", value: "billed hospital" },
	{ label: "Payment Recived", value: "payment received" },
];

export const ACTIVITY_STATUS_DROP_DOWN_OPTIONS = [
	{ label: "New", value: "new" },
	{ label: "Started", value: "started" },
	{ label: "Completed", value: "completed" },
	{ label: "Cancelled", value: "cancelled" },
	{ label: "Billable", value: 1 },
	{ label: "Non-Billable", value: 2 },
	{ label: "Hospital Contract", value: 3 },
];

export const ACTIVITY_BILL_DROP_DOWN_OPTIONS = [
	{ label: "Activity With Generated Bill", value: "Billed" },
	{ label: "Activity With Not Generated Bill", value: "UnBilled" },
];

export function getLabelByValue(value) {
	const option = BILL_STATUS_OPTTIONS.find((opt) => opt.value === value);
	return option ? option.label : null;
}

export const ROLES_LIST = [
	{
		name: "sorting-manager",
		type: "manager",
		department: "sorting",
	},
	{
		name: "data-entry-manager", //data-entry <- registration
		type: "manager",
		department: "data-entry",
	},
	{
		name: "adminstrative-manager-coding",
		type: "manager",
		department: "adminstrative-coding", //adminstrative-coding <- coding
	},
	{
		name: "charge-entry-manager",
		type: "manager",
		department: "charge-entry",
	},
	{
		name: "billing-manager",
		type: "manager",
		department: "billing",
	},
	{
		name: "sorting",
		type: "executive",
		department: "sorting",
	},
	{
		name: "data-entry",
		type: "executive",
		department: "data-entry",
	},
	{
		name: "adminstrative-coding",
		type: "executive",
		department: "adminstrative-coding",
	},
	{
		name: "charge-entry",
		type: "executive",
		department: "charge-entry",
	},
	{
		name: "billing",
		type: "executive",
		department: "billing",
	},
];

export const ALLOWED_FILE_TYPES = [
	{ label: "Appeals", value: "appeal" },
	{ label: "Authorization", value: "authorization" },
	{ label: "EOB", value: "eob" },
	{ label: "Facesheet", value: "facesheet" },
	{ label: "OP Report", value: "op" },
	{ label: "PN", value: "pn" },
	{ label: "Check", value: "check" },
	{ label: "Claim", value: "claim" },
	{ label: "Correspondence", value: "correspondence" },
];

export const PLACE_OF_SERVICE = [
	{
		label: "No Default POS",
		value: "1",
	},
	{
		label: "Office",
		value: "11",
	},
	{
		label: "Inpatient Hospital",
		value: "21",
	},
	{
		label: "Outpatient Hospital",
		value: "22",
	},
	{
		label: "Emergency Room - Hospital",
		value: "23",
	},
	{
		label: "Ambulatory Surgical Center",
		value: "24",
	},
];

export const SUGGESTED_COMMENTS = [
	"Completed.",
	"Forwarded to Someone else.",
	"In Progress.",
	"Waiting for Approval.",
];

export const ANDROID_APP_NAME = 'com.coremaxmobileapp';
export const IOS_APP_NAME = 'com.coremaxmobileapp';
