import { useEffect, useState } from "react";

const OPLTabs = ({ options, activeStep = null, disableTabAction = false }) => {
	const [active, setActive] = useState(activeStep);

	useEffect(() => {
		if (activeStep) {
			setActive(activeStep);
		} else {
			const first = options[0].id;
			setActive(first);
		}
	}, [activeStep]);

	const getActiveTabContent = () => {
		const c = options.find((o) => o.id === active);
		return c ? c.content : "";
	};

	return (
		<div className='rs-tabs'>
			<ul className='tab-selector'>
				{options.map((o, i) => (
					<li
						className={active === o.id ? "active" : ""}
						key={i}
						onClick={() => !disableTabAction && setActive(o.id)}>
						{o.title}
					</li>
				))}
			</ul>
			<div className='tab-content'>{getActiveTabContent()}</div>
		</div>
	);
};

export default OPLTabs;
