import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, APP_ROUTES, SUB_MENU } from "./constants/common";

const hasAccess = (module) => {
	const loggedInUser = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
	);
	let menuExists = false;
	if (loggedInUser.groups_users) {
		loggedInUser.groups_users.map((group) => {
			if (group.modules_groups) {
				let tIndex = group.modules_groups.findIndex(
					(t) => t.slug === module
				);
				if (tIndex >= 0) menuExists = true;
			}
		});
	}
	return menuExists;
};

export const PrivateRoute = ({ component: Component, activePage, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				const lastLogin = new Date(
					localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_TIME)
				);
				const currentTime = new Date();
				const diffTime = Math.abs(currentTime - lastLogin) / 36e5;
				if (diffTime >= 24) {
					localStorage.clear();
				}

				if (
					localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
					!hasAccess(activePage) &&
					!hasAccess(SUB_MENU[activePage])
				) {
					return (
						<Redirect
							to={{
								pathname: APP_ROUTES.NOT_AUTHORIZED,
								state: { from: props.location },
							}}
						/>
					);
				}

				return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ? (
					<Component {...props} activePage={activePage} {...rest} />
				) : (
					<Redirect
						to={{
							pathname: APP_ROUTES.LOGIN,
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};
