import { LOCAL_STORAGE_KEYS } from "../javascript/constants/common";
import { ROLES_LIST, SCANFLOW_STATUS_LIST } from "./constants";

export const formatYYYYMMDD = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr = str.split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export const formatServerDate = (str) => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // const date = new Date(str);
	const date = convertGMTToLocal(str);
    const day = date.getDate(); 
    const month = months[date.getMonth()]; 
    const year = date.getFullYear(); 

    return day + " " + month + " " + year;
};

export function formatDateTime(dateString) { 
	const date = convertGMTToLocal(dateString);
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const formattedDate = `${formatServerDate(
		dateString
	)} ${hours}:${minutes}:${seconds}`;
	return formattedDate;
}

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const formatAmountValue = (value) => {
	if (isNaN(value)) return value;
	return parseFloat(value).toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export function getScanflowStatusById(id = 0) {
	const status = SCANFLOW_STATUS_LIST.find((d) => d.id == id);
	return status
		? status
		: {
				id: "0",
				name: "Undefined",
				class: "not-started",
		  };
}

export const formatTime = (totalSeconds) => {
	const hours = Math.floor(totalSeconds / 3600)
		.toString()
		.padStart(2, "0");
	const minutes = Math.floor((totalSeconds % 3600) / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (totalSeconds % 60).toString().padStart(2, "0");
	return `${hours}:${minutes}:${seconds}`;
};

export const getLogInUserDetails = (type) => {
	const LOGGED_IN_USER = localStorage.getItem(
		LOCAL_STORAGE_KEYS.LOGGED_IN_USER
	);
	if (!LOGGED_IN_USER) return "";
	const user = JSON.parse(LOGGED_IN_USER);
	const detailsMap = {
		type: user.type,
		id: user.id,
		fullname: user.fullname,
	};
	return detailsMap[type] || "";
};

export const isManager = (roleName) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.type === "manager";
};

export const isInDepartment = (roleName, department) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.department === department;
};

export const getRoleInfo = (roleName) => {
	return ROLES_LIST.find((role) => role.name === roleName) || null;
};

export const getScanfloeStatusInfo = (key, value) => {
	return SCANFLOW_STATUS_LIST.find((status) => status[key] === value) || null;
};

export const convertGMTToLocal = (gmtInput) => {
	const gmtTime = new Date(gmtInput);
	if (isNaN(gmtTime.getTime())) {
		console.error("Invalid GMT input");
		return null;
	}
	const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
	const localTime = new Date(
		gmtTime.getTime() - timezoneOffsetInMinutes * 60000
	);
	return localTime;
};
