import { createContext, useContext, useState } from "react"; 

const ScanflowContext = createContext();

const ScanflowProvider = ({ children }) => {
	const [taskList, setTaskList] = useState(null); 
	const [task, setTask] = useState(null); 
	const [stats, setStats] = useState({
		notStarted: 0,
		completed: 0,
		inProgress: 0,
		forwarded: 0,
		paused: 0
	}); 
	const [taskLoading, setTaskLoading] = useState(false);
	const [random, setRandom] = useState(getRandomNumber());

	function getRandomNumber() {
	  const min = 1;  
	  const max = 100;
	  	  return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	const refreshHandler = () => {
		setRandom(getRandomNumber());
	  };

	return (
		<ScanflowContext.Provider
			value={{   
				random,
				taskList,
				task, 
				setTask,
				setTaskList,
				taskLoading, 
				setTaskLoading,
				refreshHandler,
				stats, 
				setStats
			}}>
			{children}
		</ScanflowContext.Provider>
	);
};

const useScanflow = () => {
	const context = useContext(ScanflowContext);
	if (!context) {
		throw new Error("Curate Provider error.");
	}
	return context;
};

export { ScanflowProvider, useScanflow };