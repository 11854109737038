import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon"; 
import Pagination from "../components/common/Pagination";  
import PatientFilter from "./patient/PatientFilter";
import { formatServerDate } from "../opl-utilities/helpers";

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");

	return queryParams;
};

const DUMMY_DATA =[
	{
	  "Id": 456675,
	  "Name": "RITIK PAWAR",
	  "DOB": "2016-01-28",
	  "Age": 0,
	  "Gender": "Female",
	  "Insurance": "Ritik test 2",
	  "Action": "editdelete"
	},
	{
	  "Id": 456674,
	  "Name": "ARINDOM KUMAR",
	  "DOB": "2016-01-28",
	  "Age": 23,
	  "Gender": "Male",
	  "Insurance": "DRIXXXXXXXXXXXXXXXX",
	  "Action": "editdelete"
	},
	{
	  "Id": 456673,
	  "Name": "ARINDOM AICH",
	  "DOB": "2016-01-28",
	  "Age": 24,
	  "Gender": "Male",
	  "Insurance": "DRIXXXXXXXXXXXXXXXX",
	  "Action": "editdelete"
	},
	{
	  "Id": 456672,
	  "Name": "YOGI P",
	  "DOB": "2016-01-28",
	  "Age": 35,
	  "Gender": "Male",
	  "Insurance": "DRIXXXXXXXXXXXXXXXX",
	  "Action": "editdelete"
	},
	{
	  "Id": 456671,
	  "Name": "TESTING IOS",
	  "DOB": "2016-01-28",
	  "Age": 0,
	  "Gender": "Male",
	  "Insurance": "DRIXXXXXXXXXXXXXXXX",
	  "Action": "editdelete"
	},
	{
	  "Id": 456670,
	  "Name": "AB RE",
	  "DOB": "2016-01-28",
	  "Age": 8,
	  "Gender": "Male",
	  "Insurance": "",
	  "Action": "editdelete"
	},
	{
	  "Id": 456669,
	  "Name": "TEST TES",
	  "DOB": "2016-01-28",
	  "Age": 1,
	  "Gender": "Male",
	  "Insurance": "",
	  "Action": "editdelete"
	}
  ]
  ;

const PatientPage = ({ history, location, activePage }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState(null);
	const queryParams = new URLSearchParams(location.search);
	const pageSize = queryParams.get("pageSize") || 10;
	const pageNo = queryParams.get("pageNo") || 0; 

	// ====== FILTER QUERY PARAMS
	const search = queryParams.get("search") || ""; 

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("patientName", ""); 
		updateQueryParam("dob", ""); 
		updateQueryParam("pageNo", 0);
	};



	const getListApi = async (s) => {
		setLoading(false);
		try { 
			const query = jsonToQueryParams({  
			});
			console.log("Query", query);
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/activity/status/${s}?pageSize=${pageSize}&pageNo=${pageNo}&${query}`, 
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": SYSTEM_TOKEN,
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setData(response.data);

				}
			}
		} catch (e) {
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	useEffect(() => {
		// getListApi();
	}, [location.search]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='insurance'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
						    Patients
						</h5>
						
						<ButtonTextIcon
							title='Add Patients'
							className='mar-l-a'
							onClick={() => history.push("/add-patient")}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19.995'
									height='19.995'
									viewBox='0 0 19.995 19.995'>
									<path
										d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
										transform='translate(-2.005 -2)'
									/>
								</svg>
							}
						/>
						&nbsp;
					</div>
					<PatientFilter
						resetFilters={() => {
							resetFilters();
						}}
					/>
					<div>
						{/* {data && !loading && ( */}
							<div
								className='flex center-left'
								style={{
									padding: "10px 15px 10px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data?.totalRecords||1000}
									pageNo={data?.pageNo||0}
									pageSize={data?.pageSize||12}
								/>
							</div>
						{/* // )} */}
					</div>
					{loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fafaff"
							}}
							className='flex center-center'>
							<LoaderIcon
								style={{ height: "32px", width: "32px" }}
							/>
						</section>
					) : (  
                                    <div className='rs-table-holder'>
                                        <table className='rs-table'>
												<thead>
													<tr>
														<th>#</th>
														<th>Full Name</th>
														<th>Date of Birth</th>
														<th>Age (Yrs)</th>
														<th>Gender (Male / Female)</th>
														<th>Insurance</th>
														<th>
															<div className='opl-tooltip'
																data-tooltip="Actions available: View, Edit, or Delete the record." >
																Action
															</div>
														</th>
													</tr>
												</thead> 
                                                <tbody> 
                                            {DUMMY_DATA.map((item, index) => (
                                                <tr key={item.Id}>
													 <td>{item.Id}</td>
													<td>{item.Name}</td>
													<td>{formatServerDate(item.DOB)}</td>
													<td>{item.Age ? item.Age + " Yrs" : "-" } </td>
													<td>{item.Gender}</td>
													<td>{item.Insurance || 'N/A'}</td>  
                                                    <td>
                                                        <div className="flex center-center" style={{ gap: '12px' }}>
                                                            <div className="opl-tooltip" data-tooltip='Click to View Patient Details.' >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="14"
                                                                    viewBox="0 0 20 14"
                                                                    style={{ height: '16px', width: '16px' }}>
                                                                    <path
                                                                        d="M12,5C7.92,5,4.256,8.093,2.145,11.483a1,1,0,0,0,0,1.034C4.256,15.907,7.92,19,12,19c4.143,0,7.8-3.09,9.864-6.493a1,1,0,0,0,0-1.014C19.794,8.09,16.141,5,12,5Zm8.413,7c-1.837,2.878-4.9,5.5-8.413,5.5-3.465,0-6.532-2.632-8.4-5.5C5.465,9.132,8.533,6.5,12,6.5,15.516,6.5,18.577,9.124,20.411,12ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm0,1.5A2.5,2.5,0,1,0,14.5,12,2.5,2.5,0,0,0,12,9.5Z"
                                                                        transform="translate(-2 -5)"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="opl-tooltip" data-tooltip='Click to Edit Patient Details.'>
                                                                <NavLink
                                                                    to={{
                                                                        pathname: '/edit-patient',
                                                                        state: { id: index }
                                                                    }}
                                                                >
                                                                    <svg
                                                                        width="20"
                                                                        height="14"
                                                                        style={{ height: '16px', width: '16px' }}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
                                                                            fill="#000000"
                                                                        />
                                                                    </svg>
                                                                </NavLink>
                                                            </div>
															<div className="opl-tooltip" data-tooltip='Click to Delete Patient.'> 
																		<svg
																			height="15px"
																			width="15px"
                                                                            style={{ height: '16px', width: '16px' }}
																			viewBox="0 0 330 330" 
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg> 
															</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))} 
                                                </tbody> 
                                        </table>
                                    </div> 
					)}

					<div>
					</div> 
					<div>
						{/* {data && !loading && ( */}
							<div
								className='flex center-center'
								style={{
									padding: "24px 15px 32px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data?.totalRecords||1000}
									pageNo={data?.pageNo||0}
									pageSize={data?.pageSize||12}
								/>
							</div>
						{/* )} */}
					</div>
				</section>
			</section>
		</section>
	);
};
 
export default withRouter(PatientPage);