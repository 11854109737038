import { createContext, useContext, useState, useCallback } from "react"; 
import SingleToast from "../components/common/SingleToast";

const UIContext = createContext();

const UIProvider = ({ children }) => {  
	const [toastMessage, setToastMessage] = useState([]); 

	const addToastMessage = (d) => {
		setToastMessage((toastMessage) => [
			{
				...d,
				id: Date.now(),
			},
			...toastMessage,
		]);
	};

	const removeToast = useCallback((id) => {
		setToastMessage((prevToasts) =>
			prevToasts.filter((toast) => toast.id !== id)
		);
	}, []);

	return (
		<UIContext.Provider
			value={{   
				addToastMessage,
			}}>
			{toastMessage.length > 0 && (
				<div id='opl_coremax' >
					<div className="toast-messages" >
						{toastMessage.map((t, i) => (
							<SingleToast
								data={t}
								key={t.id}
								removeToast={removeToast}
							/>
						))}
					</div>
				</div>
			)}
			{children}
		</UIContext.Provider>
	);
};

const useUI = () => {
	const context = useContext(UIContext);
	if (!context) {
		throw new Error("Curate Provider error.");
	}
	return context;
};

export { UIProvider, useUI };